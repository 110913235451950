import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './utils';

const GET_HIST_RENTALS= '/hist_rentals/';

export const rentalsApi = createApi({
    reducerPath: 'rentalsApi',
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        getRentalInvoice: builder.query({
            query: ({ params: { id }}) => ({
                url: `${ GET_HIST_RENTALS }${ id }/invoices`,
            }),
            forceRefetch: () => true,
        }),
        reissueRentalInvoice: builder.mutation({
            query: ({ params: { id, ...payload} }) => ({
                url: `${ GET_HIST_RENTALS }${ id }/invoices`,
                method: 'POST',
                body: payload,
            }),
        })
    })
});

export const {
    useLazyGetRentalInvoiceQuery,
    useReissueRentalInvoiceMutation,
} = rentalsApi;
