import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { CardBody, Card } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import Select from 'react-select';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Form/Button';
import Form from 'components/Form';
import Input, {
    TYPE_NUMBER,
    TYPE_RADIO,
} from 'components/Form/Input';
import {
    REFUND_BY_MONEY,
    REFUND_BY_COUPON,
} from 'constants/rental';
import {
    fetchRefundCoupon,
} from 'actions';
import ControlTemplate from './ControlTemplate';


const CouponSelectField = ({ coupons, onChange }) => {
    const options = coupons.map(
        ({ coupon_id, title }) => ({
            value: coupon_id,
            label: title,
        })
    );
    const handleChange = opt => {
        onChange(opt.value);
    };

    return (
        <ControlTemplate caption="rental.compensation">
            <Select
                options={ options }
                onChange={ handleChange }
            />
        </ControlTemplate>
    );
};
CouponSelectField.propTypes = {
    coupons: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onChange: PropTypes.func.isRequired,
};

const FULL_REFUND = 0;
const PARTIAL_REFUND = 1;


class RefundModal extends Component {
    static propTypes = {
        type: PropTypes.oneOf([
            REFUND_BY_MONEY,
            REFUND_BY_COUPON,
        ]).isRequired,
        show: PropTypes.bool.isRequired,
        charged: PropTypes.number,
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        couponList: ImmutablePropTypes.list.isRequired,
    };

    static defaultProps = {
        charged: 0,
        onClose: () => {},
        onSubmit: () => {},
    };

    constructor(props) {
        super(props);

        this.couponId = undefined;
        this.state = {
            inProgress: false,
            refundType: -1,
            refundValue: 0,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fetchRefundCoupon());
    }

    handleClose = () => {
        const { onClose } = this.props;
        this.setState({
            refundType: -1,
            refundValue: 0,
        });
        onClose();
    }

    handleCouponChange = couponId => {
        this.couponId = couponId;
    }

    handleRefund = () => {
        const { onSubmit } = this.props;
        const { refundValue } = this.state;

        return onSubmit(refundValue, REFUND_BY_MONEY);
    }

    handleSubmit = () => {
        const { onSubmit } = this.props;
        this.setState({
            inProgress: true,
        }, () => {
            onSubmit({
                coupon_id: this.couponId,
            }).then(() => {
                this.setState({
                    inProgress: false,
                });
            });
        });

    }

    handleRefundTypeChange = refund => {
        const { charged } = this.props;
        this.setState({
            refundType: refund[0],
            refundValue: refund[0] === FULL_REFUND ? charged : 0,
        });
    }

    handleRefundValueChange = event => {
        const value = event.target.value;
        this.setState({
            refundValue: value,
        });
    }

    render() {
        const { inProgress, refundType, refundValue } = this.state;
        const { type, couponList, charged, show } = this.props;
        const coupons = couponList.toJS();

        const isInvalid = refundType === -1
        || refundValue === ''
        || refundValue < 1
        || refundValue > charged
        || !Number.isInteger(Number(refundValue));

        return show ? (
            <Modal
                className="view-documents-modal refund-modal"
                title={ I18n.t('rental.refund') }
                disabledCloseOnBg
                onClose={ this.handleClose }
            >
                <Form inline onSubmit={ type === REFUND_BY_MONEY ? this.handleRefund : this.handleSubmit } inProgress={ inProgress } stopSubmitByEnter>
                    <Card>
                        <CardBody>
                            <Input
                                assignedstyle="result-input-total"
                                type={ TYPE_NUMBER }
                                caption="rental.total_charged"
                                value={ charged }
                                viewMode
                            />
                            {
                                type === REFUND_BY_MONEY ? (
                                    <>
                                        <Input
                                            assignedstyle="refund-type"
                                            name="refund"
                                            type={ TYPE_RADIO }
                                            value={ [{
                                                name: <div className="full-refund">{ I18n.t('customer.full_refund') }</div>,
                                                value: FULL_REFUND,
                                                translate: true,
                                            }, {
                                                name: (
                                                    <div className="partial-refund">
                                                        <span className="partial-refund-text">{ I18n.t('customer.partial_refund') }</span>
                                                            <Input
                                                                disabled={ refundType !== PARTIAL_REFUND}
                                                                type={ TYPE_NUMBER }
                                                                min="0"
                                                                max={ charged }
                                                                unitCaption={ I18n.t('customer.dollars') }
                                                                onChange={ this.handleRefundValueChange }
                                                                value={ refundType === FULL_REFUND ? 0 : refundValue }
                                                            />
                                                    </div>
                                                ),
                                                value: PARTIAL_REFUND,
                                                translate: true,
                                            }] }
                                            caption="rental.refund"
                                            onChange={ this.handleRefundTypeChange }
                                            selected={ refundType }
                                        />
                                    </>
                                ) : (
                                    <CouponSelectField coupons={ coupons } onChange={ this.handleCouponChange } />
                                )
                            }
                        </CardBody>
                    </Card>
                    {
                        type === REFUND_BY_MONEY && (
                            <div className="refund-result">
                                <Input
                                    assignedstyle="result-input"
                                    type={ TYPE_NUMBER }
                                    caption="customer.original_charge"
                                    value={ charged }
                                    viewMode
                                />
                                <Input
                                    assignedstyle="result-input result-refund"
                                    type={ TYPE_NUMBER }
                                    caption="rental.refund"
                                    value={ isInvalid ? '--' : parseInt(refundValue) }
                                    viewMode
                                />
                                <Input
                                    assignedstyle="result-input"
                                    type={ TYPE_NUMBER }
                                    caption="customer.final_charge"
                                    value={ isInvalid ? '--' : (charged - refundValue) }
                                    viewMode
                                />
                            </div>
                        )
                    }

                    <ButtonGroup>
                        {
                            type === REFUND_BY_MONEY && (
                                <Button
                                    disabled={ isInvalid }
                                    type="button"
                                    color="primary"
                                    onClick={ this.handleClose }
                                    outline
                                >
                                    <Translate value="cancel" />
                                </Button>
                            )
                        }
                        <Button
                            disabled={ type === REFUND_BY_MONEY && isInvalid }
                            type="submit"
                            color="primary"
                        >
                            <Translate value="apply" />
                        </Button>
                    </ButtonGroup>
                </Form>
            </Modal>
        ) : null;
    }
}

export default connect(state => ({
    couponList: state.rental.get('coupon_list'),
}))(RefundModal);
