import { useState, useCallback } from 'react';
import { store as rtkStore } from "redux/store";
import { travelPassApi } from "redux/services/travelPassApi";

export const useLazyGetTravelPassInvoiceQuery = (options) => {
    const { getTravelPassInvoice } = travelPassApi.endpoints;
    const [result, setResult] = useState();
    const fetch = useCallback(async (params) => {
        const response =  await rtkStore.dispatch(getTravelPassInvoice.initiate({ params }, options));
        setResult(response);
        return response;
    }, [options, getTravelPassInvoice]);


    return [
        fetch,
        result,
    ];
};

export const useReissueTravelPassInvoiceMutation = () => {
    const { reissueTravelPassInvoice } = travelPassApi.endpoints;
    const [result, setResult] = useState();
    const fetch = useCallback(async (params) => {
        const response =  await rtkStore.dispatch(reissueTravelPassInvoice.initiate({ params }));
        setResult(response);
        return response;
    }, [reissueTravelPassInvoice]);

    return [
        fetch,
        result,
    ];
};
