import {
    PAYMENT_STATUS_SUCCESS,
    PAYMENT_STATUS_FAIL,
    PAYMENT_STATUS_UNPAID,
    PAYMENT_STATUS_PENDING,

    RENTAL_ACTION_TIMEOUT,
    RENTAL_ACTION_RESERVE,
    RENTAL_ACTION_CANCEL,
    RENTAL_ACTION_BEEP,
    RENTAL_ACTION_RIDE,
    RENTAL_ACTION_PARK,
    RENTAL_ACTION_OPEN_TRUNK,
    RENTAL_ACTION_RETURN,

    RENTAL_SIMPLIFIED_IDLE,
    RENTAL_SIMPLIFIED_RESERVING,
    RENTAL_SIMPLIFIED_RENTING,

    RENTAL_END,
    RENTAL_RESERVING,
    RENTAL_RIDING,
    RENTAL_PARKING,
    RENTAL_CANCEL,
    RENTAL_PAUSE,

    REFUND_BY_MONEY,
    REFUND_BY_COUPON,

    PAYMENT_CHANNEL_TAPPAY,
    PAYMENT_CHANNEL_GOPOCKET,

    INVOICE_TYPE_GSH,
    INVOICE_TYPE_UBN,
} from 'constants/rental';
const paymentStatus = {
    [PAYMENT_STATUS_SUCCESS]: '成功',
    [PAYMENT_STATUS_FAIL]: '失敗',
    [PAYMENT_STATUS_UNPAID]: '尚未付款',
    [PAYMENT_STATUS_PENDING]: '付款處理中',
};
const rentalGroupingState = {
    [RENTAL_SIMPLIFIED_IDLE]: '閒置',
    [RENTAL_SIMPLIFIED_RESERVING]: '預約中',
    [RENTAL_SIMPLIFIED_RENTING]: '租賃中',
};
const rentalSpecificState = {
    [RENTAL_END]: '還車',
    [RENTAL_RESERVING]: '預約中',
    [RENTAL_RIDING]: '租賃中',
    [RENTAL_PARKING]: '停車',
    [RENTAL_CANCEL]: '取消預約',
    [RENTAL_PAUSE]: '暫停中',
};
const rentalAction = {
    [RENTAL_ACTION_TIMEOUT]: '逾時',
    [RENTAL_ACTION_RESERVE]: '預約',
    [RENTAL_ACTION_CANCEL]: '取消',
    [RENTAL_ACTION_BEEP]: '尋車',
    [RENTAL_ACTION_RIDE]: '騎乘',
    [RENTAL_ACTION_PARK]: '停車',
    [RENTAL_ACTION_OPEN_TRUNK]: '開車廂',
    [RENTAL_ACTION_RETURN]: '還車',
};
const refundType = {
    [REFUND_BY_MONEY]: '退費',
    [REFUND_BY_COUPON]: '優惠券',
};

const paymentChannel = {
    [PAYMENT_CHANNEL_TAPPAY]: 'TapPay訂單編號',
    [PAYMENT_CHANNEL_GOPOCKET]: '訂單編號',
};

const GSHPassPaymentChannel = {
    [PAYMENT_CHANNEL_TAPPAY]: 'TapPay',
    [PAYMENT_CHANNEL_GOPOCKET]: 'Gogoro Wallet',
};

const invoiceType = {
    [INVOICE_TYPE_GSH]: '個人發票(類別：會員載具)',
    [INVOICE_TYPE_UBN]: '統編發票',
};

const rental = {
    'document_title': '租賃管理',
    'rental_detail': '租賃詳細資訊',
    'rental_no': '租賃紀錄編號',
    'payment_channel': paymentChannel,
    'invoice_no': '發票號碼',
    'reissue_invoice': '換開發票',
    'subtotal': '小計',
    'billing': '計費',
    'discount': '折扣',
    'charged': '收費',
    'area_discount': '區域限定折扣',
    'total_charged': '總收費',
    'gogoro_rewards': 'Gogoro Rewards',
    'time': '時間',
    'information': '付款資訊',
    'trip': '旅程',
    'price': '金額',
    'refund': '退費',
    'refunded': '退費金額',
    'allowance': '企業優惠',
    'compensated': '補償',
    'compensation': '補償',
    'net_soc_change': '總電量差(%)',
    'payment_status_map': paymentStatus,
    'rental_grouping_state_map': rentalGroupingState,
    'rental_specific_state_map': rentalSpecificState,
    'rental_action_map': rentalAction,
    'refund_type_map': refundType,
    'refund_failed': '退款失敗(%{code})',
    'refund_failed_go_rewards': '退款失敗',
    'partial_refund_failed': '此款項尚未請款，無法部分退款',
    'change_amount': '修改金額',
    'change_amount_confirm': '修改金額確認',
    'original_amount': '原金額',
    'new_amount': '新金額',
    'chagne_amount_failed': '修改金額失敗(%{code})',
    'record_periods': '查詢期間',
    'battery_swap_count': '低電量換電次數',
    'photo': '還車照片',
    'purchase_discount': '公共運輸定期票優惠',
    'pause_duration': '暫停時間',
    'ride_duration': '騎乘時間',
    'daypass': '日租方案',
    'daypass_valid_from': '啟用時間',
    'daypass_valid_to': '到期時間',
    'daypass_acc_distance': '已使用里程',
    'daypass_payment_channel': GSHPassPaymentChannel,
    'daypass_over_time': '超時',
    'daypass_over_mileage': '超里程',
    'refund_confirm': '退款確認',
    'reissue_invoice_title': '換開發票',
    'reissue_invoice_type': '發票類型',
    'invoice_type': invoiceType,
    'invoice_ubn_code': '統一編號',
    'invoice_ubn_title': '抬頭',
    'invoice_list_title': '發票歷程',
    'invoice_issuing': '(發票開立中)',
    'invoice_random_code': '隨機碼',
    'invoice_empty': '付款成功但發票尚未開立，請稍後再查看',
    'invoice_is_issuing': '換開新發票中，請稍後再查看',
    'invoice_note_fail': '折讓單開立中，請稍後再查看',
    'invoice_uncharged_error': '該款項無收費，無法進行換開發票',
    'invoice_ubn_format_error': '統編號碼或抬頭格式錯誤',
    'daypass_end_time': '日租方案到期時間'
};

export default rental;
