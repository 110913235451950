import {
    CLAIM_TYPE_NO_CLAIM,
    CLAIM_TYPE_CLAIM,

    FEE_TYPE_FINE,
    FEE_TYPE_WITHHOLDING,
    FEE_TYPE_DISPATCH,
    FEE_TYPE_REPAIR,

    FEE_STATUS_UNPAID,
    FEE_STATUS_FAILED,
    FEE_STATUS_PAID,
    FEE_STATUS_REFUND,
    FEE_STATUS_CANCEL,
    FEE_STATUS_PROCESSING,
    FEE_STATUS_PROCESS_SUCCESS,
    FEE_STATUS_PROCESS_FAILED,
    FEE_STATUS_ABSORBED_BY_INTR,

    FEE_SUBTYPE_VIOLATE_ILLEGAL_PARKING,
    FEE_SUBTYPE_VIOLATE_NON_ILLEGAL_PARKING,
    FEE_SUBTYPE_CHARGE_PRIVATE_PARKING,
    FEE_SUBTYPE_CHARGE_TOWING_YARD,
    FEE_SUBTYPE_TOWING_ILLEGAL_PARKING,
    FEE_SUBTYPE_TOWING_TOWING_YARD,
    FEE_SUBTYPE_TOWING_OUTSIDE,
    FEE_SUBTYPE_TOWING_TRAFFIC_ACCIDENT,
    FEE_SUBTYPE_TOWING_DRUNK_DRIVING,
    FEE_SUBTYPE_REPAIR_CAR_DAMAGE,
    FEE_SUBTYPE_REPAIR_SUSPECTED_DAMAGE,

    VIOLATE_ZONE_KLU,
    VIOLATE_ZONE_TPE,
    VIOLATE_ZONE_TPH,
    VIOLATE_ZONE_TYC,
    VIOLATE_ZONE_HSC,
    VIOLATE_ZONE_HSH,
    VIOLATE_ZONE_MAL,
    VIOLATE_ZONE_TXG,
    VIOLATE_ZONE_CWH,
    VIOLATE_ZONE_NTO,
    VIOLATE_ZONE_YLH,
    VIOLATE_ZONE_CYI,
    VIOLATE_ZONE_CHY,
    VIOLATE_ZONE_TNN,
    VIOLATE_ZONE_KHH,
    VIOLATE_ZONE_IUH,
    VIOLATE_ZONE_TTT,
    VIOLATE_ZONE_HWA,
    VIOLATE_ZONE_ILN,
    VIOLATE_ZONE_PEH,
    VIOLATE_ZONE_KMN,
    VIOLATE_ZONE_LNN,

    ERROR_RENTAL_NOT_FOUND,
    ERROR_ZENDESK_NO_EMPTY,
    ERROR_FEE_TYPE_ERROR,
    ERROR_AMOUNT_OUT_OF_RANGE,
    ERROR_RENTAL_STATUS_ERROR,
    ERROR_RENTAL_PLATE_NOT_MATCH,
    ERROR_RENTAL_PLATE_NOT_FOUND,
    ERROR_RENTAL_SUBTYPE_ERROR,
    ERROR_RENTAL_PLATE_EMPTY,
    ERROR_VIOLATE_TIME_EMPTY,
    ERROR_TICKET_NO_EMPTY,
    ERROR_ATTACHMENT_DATA_ERROR,

    ERROR_EXPENSE_NOT_FOUND,
    ERROR_EXPENSE_STATUS_ERROR,
} from 'constants/additional-fee';

import commonTrans from './';

const claimType = {
    [CLAIM_TYPE_NO_CLAIM]: '不索費',
    [CLAIM_TYPE_CLAIM]: '索費',
};

const feeType = {
    [FEE_TYPE_FINE]: '罰單',
    [FEE_TYPE_WITHHOLDING]: '其他',
    [FEE_TYPE_DISPATCH]: '調度',
    [FEE_TYPE_REPAIR]: '維修',
};

const feeStatus = {
    [FEE_STATUS_UNPAID]: '尚未付款',
    [FEE_STATUS_PAID]: '成功',
    [FEE_STATUS_REFUND]: '退款',
    [FEE_STATUS_CANCEL]: '已刪除',
    [FEE_STATUS_FAILED]: '失敗',
    [FEE_STATUS_PROCESSING]: '處理中',
    [FEE_STATUS_PROCESS_SUCCESS]: '處理結果-成功',
    [FEE_STATUS_PROCESS_FAILED]: '處理結果-失敗',
    [FEE_STATUS_ABSORBED_BY_INTR]: '自行吸收',
};

const feeSubtype = {
    [FEE_SUBTYPE_VIOLATE_ILLEGAL_PARKING]: '交通違規-違停類',
    [FEE_SUBTYPE_VIOLATE_NON_ILLEGAL_PARKING]: '交通違規-非違停類',
    [FEE_SUBTYPE_CHARGE_PRIVATE_PARKING]: '私人停車場收費',
    [FEE_SUBTYPE_CHARGE_TOWING_YARD]: '拖吊場收費',
    [FEE_SUBTYPE_TOWING_ILLEGAL_PARKING]: '違停拖吊',
    [FEE_SUBTYPE_TOWING_TOWING_YARD]: '拖吊場拖吊',
    [FEE_SUBTYPE_TOWING_OUTSIDE]: '營運範圍外拖吊',
    [FEE_SUBTYPE_TOWING_TRAFFIC_ACCIDENT]: '交通事故拖吊',
    [FEE_SUBTYPE_TOWING_DRUNK_DRIVING]: '酒駕拖吊',
    [FEE_SUBTYPE_REPAIR_CAR_DAMAGE]: '車損維修費',
    [FEE_SUBTYPE_REPAIR_SUSPECTED_DAMAGE]: '疑似車損維修費',
};

const violateZone = {
    [VIOLATE_ZONE_TPE]: '臺北市',
    [VIOLATE_ZONE_TPH]: '新北市',
    [VIOLATE_ZONE_TYC]: '桃園市',
    [VIOLATE_ZONE_TXG]: '臺中市',
    [VIOLATE_ZONE_TNN]: '臺南市',
    [VIOLATE_ZONE_KHH]: '高雄市',
    [VIOLATE_ZONE_YLH]: '雲林縣',
    [VIOLATE_ZONE_HSC]: '新竹市',
    [VIOLATE_ZONE_HSH]: '新竹縣',
    [VIOLATE_ZONE_KLU]: '基隆市',
    [VIOLATE_ZONE_MAL]: '苗栗縣',
    [VIOLATE_ZONE_CWH]: '彰化縣',
    [VIOLATE_ZONE_NTO]: '南投縣',
    [VIOLATE_ZONE_CHY]: '嘉義縣',
    [VIOLATE_ZONE_CYI]: '嘉義市',
    [VIOLATE_ZONE_IUH]: '屏東縣',
    [VIOLATE_ZONE_ILN]: '宜蘭縣',
    [VIOLATE_ZONE_HWA]: '花蓮縣',
    [VIOLATE_ZONE_TTT]: '臺東縣',
    [VIOLATE_ZONE_PEH]: '澎湖縣',
    [VIOLATE_ZONE_KMN]: '金門縣',
    [VIOLATE_ZONE_LNN]: '連江縣',
};

const additional_fee = {
    'claim_type_map': claimType,
    'fee_type_map': feeType,
    'fee_status_map': feeStatus,
    'claim_type': '索費類型',
    'fee_type': '費用項目',
    'status': '狀態',
    'ticket_no': '罰單號碼',
    'new': '新增費用',
    'request_no': '費用編號',
    'fee_subtype_map': feeSubtype,
    'subtype': '子分類',
    'details': '詳細資料',
    'claim_type_warning': '(*提交後將立即通知使用者此筆費用)',
    'violate_zone': '事發地區',
    'violate_zone_map': violateZone,
    'zendesk_no': 'Zendesk No.',
    'comments': '留言',
    'comments_ps': '(僅供內部紀錄使用)',
    'attachments_interal': '(最多可上傳 3 筆，並僅供內部紀錄使用)',
    'attachments_external': '(最多可上傳 3 筆，並將寄送給使用者)',
    'file_size_8MB_warning': '檔案大小不得超過 8 MB',
    'information': '租賃資訊',
    'search_title': '租賃紀錄資料',
    'violate_time': '事發時間',
    'violate_search_hint': '若修改“租賃紀錄編號”、“車牌號碼”、“事發時間”，將清除搜尋結果，需重新搜尋紀錄。',
    'violate_search_init_msg': '請搜尋租賃紀錄',
    'btn_search_rental': '搜尋租賃紀錄',
    'btn_view_rental': '顯示租賃紀錄',
    [`create_error_message_${ ERROR_RENTAL_NOT_FOUND }`]: '租賃紀錄編號不存在',
    [`create_error_message_${ ERROR_ZENDESK_NO_EMPTY }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_FEE_TYPE_ERROR }`]: '金額格式錯誤',
    [`create_error_message_${ ERROR_AMOUNT_OUT_OF_RANGE }`]: '金額超出範圍',
    [`create_error_message_${ ERROR_RENTAL_STATUS_ERROR }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_RENTAL_PLATE_NOT_MATCH }`]: '和租賃紀錄內車牌號碼不相符',
    [`create_error_message_${ ERROR_RENTAL_PLATE_NOT_FOUND }`]: '不存在的車牌',
    [`create_error_message_${ ERROR_RENTAL_SUBTYPE_ERROR }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_RENTAL_PLATE_EMPTY }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_VIOLATE_TIME_EMPTY }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_TICKET_NO_EMPTY }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_ATTACHMENT_DATA_ERROR }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_EXPENSE_NOT_FOUND }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_EXPENSE_STATUS_ERROR }`]: commonTrans.general_error,
    'fee_detail_title': '費用詳情',
    'fee_info': '費用資訊',
    'revoke_fee': '刪除',
    'revoke_warning': '確認刪除此筆費用嗎？',
    'absorbed_fee': '自行吸收',
    'absorbed_warning': '確認將此筆費用改為自行吸收嗎？',
    'confirm_save_claim_fee': '該筆費用為索費費用，儲存後將寄送相關資訊與附件給使用者並索取費用。請再次確認必填資訊。',
    'confirm_save_claim_fee_title': '確認資訊',
    'only_allow_jpe': '只允許上傳 jpg/jpeg 類型的圖檔。'
};

export default additional_fee;
