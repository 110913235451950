const ListHeader = [{
    name: 'transaction_type',
    value: 'retrieval.type',
    width: 80,
}, {
    name: 'transaction_record',
    value: 'retrieval.rental_id',
    width: 300,
}, {
    name: 'order_id',
    value: 'retrieval.order_id',
    width: 200,
}, {
    name: 'amount',
    value: 'retrieval.rental_amount',
    width: 80,
}, {
    name: 'bin_code',
    value: 'retrieval.card_first',
    width: 200,
}, {
    name: 'last_four_code',
    value: 'retrieval.card_last',
    width: 200,
}, {
    name: 'credit_card_issuer',
    value: 'retrieval.issuer',
    width: 200,
}, {
    name: 'credit_card_type',
    value: 'retrieval.card_type',
    width: 200,
}];

export default ListHeader;
