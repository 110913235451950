import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { updateDocumentTitle, toggleErrorDialog } from 'actions';
import buildActualPath from 'helpers/build-actual-path';
import { ADDITIONAL_FEE_DETAIL } from 'constants/routes';
import AuthView from 'components/AuthView';
import NavigationBar from 'components/NavigationBar';
import serialize from 'form-serialize';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import { Translate, I18n } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import FlexGroup from 'components/FlexGroup';
import UploadAttachments, { initAttachments } from 'components/UploadAttachments';
import ViolateRental from '../ViolateRental';
import { convertToUTC } from 'helpers/time-handler';
import {
    TYPE_RADIO,
    TYPE_SELECT,
    TYPE_NUMBER,
    TYPE_TEXT,
    TYPE_TEXTAREA,
} from 'components/Form/Input/input-type';
import {
    CLAIM_TYPE_NO_CLAIM,
    CLAIM_TYPE_CLAIM,
    CLAIM_TYPE_GROUP,
    FEE_TYPE_GROUP,
    FEE_SUBTYPE_GROUP,
    VIOLATE_ZONE_GROUP,
    FEE_TYPE_FINE,
} from 'constants/additional-fee';
import {
    CLAIM_TYPE,
    FEE_TYPE,
    FEE_SUBTYPE,
} from '../Filter';
import {
    useUploadFeeAttachmentMutation,
    useCreateExpenseMutation,
} from 'redux/hook/useAdditionalFee';

import './new-fee.scss';
import ClaimWarning from '../ClaimWarning';

const DEFAULT_FEE_TYPE = '';
const DEFAULT_FEE_SUBTYPE = '';
export const DEFAULT_VIOLATE_ZONE = '';

const ACCEPTED_ATTACHMENTS_TYPE = 'image/jpeg';
const ATTACHMENT_SIZE_LIMIT = 8 * 1024 * 1024;
export const ATTACHMENT_MAX_COUNT = 3;

const AMOUNT = 'amount';
const VIOLATE_ZONE = 'violate_zone';
const TICKET_NO = 'ticket_no';
const ZENDESK_NO = 'zendesk_no';
const COMMENTS = 'comments';
const ATTACHMENTS = 'attachments';

const NewAdditionalFee = ()=> {
    const dispatch = useDispatch();
    const i18n = useSelector(state => state.i18n);
    const history = useHistory();

    useEffect(() => {
        dispatch(updateDocumentTitle(`additional_fee.new`));

    }, [dispatch, i18n]);

    const [showClaimWarning, setShowClaimWarning] = useState(false);
    const formRef = useRef();
    const [claimType, setClaimType] = useState(CLAIM_TYPE_NO_CLAIM);
    const [feeType, setFeeType] = useState(DEFAULT_FEE_TYPE);
    const [subtype, setSubtype] = useState(DEFAULT_FEE_SUBTYPE);
    const [amount, setAmount] = useState('');
    const [violateZone, setViolateZone] = useState(DEFAULT_VIOLATE_ZONE);
    const [ticketNo, setTicketNo] = useState('');
    const [zendeskNo, setZendeskNo] = useState('');
    const [comments, setComments] = useState('');
    const [attachments, setAttachments] = useState(initAttachments(ATTACHMENT_MAX_COUNT));

    const [submitLoading, setSubmitLoading] = useState(false);

    const [uploadFeeAttachment] = useUploadFeeAttachmentMutation();
    const [createExpense] = useCreateExpenseMutation();

    const closeClaimWarning = () => {
        setShowClaimWarning(false);
    };

    const getClaimTypeOpts = () => {
        return CLAIM_TYPE_GROUP.map(type => ({
            value: type,
            name: `${ I18n.t(`additional_fee.claim_type_map.${ type }`) } ${ type === CLAIM_TYPE_CLAIM ? I18n.t('additional_fee.claim_type_warning') : '' }`
        }));

    };
    const getFeeTypeOpts = () => {
        return ([
            DEFAULT_FEE_TYPE,
            ...FEE_TYPE_GROUP,
        ].map(value => {
            return value !== DEFAULT_FEE_TYPE ? {
                name: I18n.t(`additional_fee.fee_type_map.${ value }`),
                value,
            } : {
                name: '',
                value: DEFAULT_FEE_TYPE,
            }
        }));
    };
    const getFeeSubtypeOpts = () => {
        if (feeType === DEFAULT_FEE_TYPE) {
            return [];
        }
        else {
            return ([
                DEFAULT_FEE_SUBTYPE,
                ...FEE_SUBTYPE_GROUP[feeType],
            ].map(value => {
                return value !== DEFAULT_FEE_SUBTYPE ? {
                    name: I18n.t(`additional_fee.fee_subtype_map.${ value }`),
                    value,
                } : {
                    name: '--',
                    value: DEFAULT_FEE_SUBTYPE,
                }
            }));
        }

    };

    const getViolateZoneOpts = () => {
        return ([
            DEFAULT_VIOLATE_ZONE,
            ...VIOLATE_ZONE_GROUP,
        ].map(value => {
            return value !== DEFAULT_VIOLATE_ZONE ? {
                name: I18n.t(`additional_fee.violate_zone_map.${ value }`),
                value,
            } : {
                name: '',
                value: DEFAULT_VIOLATE_ZONE,
            }
        }));
    };

    const handleFormChange = e => {
        const { name, value } = e.target;
        switch (name) {
            case CLAIM_TYPE:
                setClaimType(Number(value));
                break;
            case FEE_TYPE:
                setFeeType(value === DEFAULT_FEE_TYPE ? value : Number(value));
                setSubtype(DEFAULT_FEE_SUBTYPE);
                setTicketNo('');
                break;
            case FEE_SUBTYPE:
                setSubtype(value === DEFAULT_FEE_SUBTYPE ? value :  Number(value));
                break;
            case AMOUNT:
                setAmount(value === '' ?  '' : Number(value));
                break;
            case VIOLATE_ZONE:
                setViolateZone(value === DEFAULT_VIOLATE_ZONE ? value :  Number(value));
                break;
            case TICKET_NO:
                setTicketNo(value);
                break;
            case ZENDESK_NO:
                setZendeskNo(value);
                break;
            case COMMENTS:
                setComments(value);
                break;
            default:
        }
    };
    const handleAttachmentUpdate = (no, e) => {
        const { files } =  e.target;

        if (files[0].type !== ACCEPTED_ATTACHMENTS_TYPE) {
            dispatch(toggleErrorDialog(I18n.t('additional_fee.only_allow_jpe')));
            e.target.value = null;
            return;
        }

        if (files[0].size > ATTACHMENT_SIZE_LIMIT) {
            dispatch(toggleErrorDialog(I18n.t('additional_fee.file_size_8MB_warning')));
            e.target.value = null;
            return;
        }
        const newFiles = attachments.map((item, index) => (index !== no) ? item : files[0]);
        setAttachments(newFiles);
        e.target.value = null;
    };

    const deleteAttachment = (index) => {
        const newAttachmentArray = attachments.map((file, i) => (i !== index)? file : '');
        setAttachments(newAttachmentArray);
    };

    const getFormData = () => {
        const formData = serialize(formRef.current, { hash: true });
        const {
            claim_type,
            fee_type,
            subtype,
            amount,
            ticket_no,
            zendesk_no,
            violate_zone,
            violate_time,
            plate_no,
            comments,
            rental_id,
        } = formData;
        return {
            claim_type: Number(claim_type),
            fee_type: Number(fee_type),
            subtype: Number(subtype),
            amount: Number(amount),
            violate_zone: Number(violate_zone),
            ticket_no,
            zendesk_no,
            comments,
            violate_time: convertToUTC(violate_time),
            plate_no,
            rental_id,
        };

    };

    const handleCreateSubmit = hasClaimWarning => (e) => {
        if (submitLoading) return;

        if (hasClaimWarning && claimType === CLAIM_TYPE_CLAIM) {
            setShowClaimWarning(true);
            return;
        }
        setShowClaimWarning(false);
        setSubmitLoading(true);

    };

    const createFee = useCallback(async () => {
        let uploadResult;
        let feeData = getFormData();
        const uploadedAttachments = attachments.filter(item => item !== '');
        try {
            if (uploadedAttachments.length) {
                uploadResult = await uploadFeeAttachment({
                    files: uploadedAttachments,
                });

                if (!uploadResult) throw new Error('attachments_uploaded_fail');
            }
            const { data, error } = await createExpense({
                ...feeData,
                attachments: uploadedAttachments.length ? uploadResult.data : undefined,
            });
            if (error) {
                throw error;
            }
            if (data) {
                const { expense_id } = data;
                history.replace(buildActualPath(ADDITIONAL_FEE_DETAIL, { expenseId: expense_id }));
            }
        }
        catch (error) {

            if (error.message) {
                dispatch(toggleErrorDialog(
                    I18n.t(`additional_fee.${error.message}`),
                    () => {
                        setSubmitLoading(false);
                    }
                ));
                return ;
            }
            const { code } = error?.data || {};

            const message = (code !== undefined && code > 0) ? I18n.t(`additional_fee.create_error_message_${ code }`) : I18n.t('general_error_message');

            dispatch(toggleErrorDialog(message, () => {
                setSubmitLoading(false);
            }));
        }
    }, [attachments, createExpense, dispatch, history, uploadFeeAttachment]);



    useEffect(()=> {
        if (submitLoading) {
            createFee();
        }
    }, [submitLoading, createFee]);

    return(
        <AuthView className="new-additional-fee">
            <Form
                innerRef={ formRef }
                onSubmit={ handleCreateSubmit(true) }
                onChange={ handleFormChange }
                inProgress={ submitLoading }
                inline
                stopSubmitByEnter
            >
                <NavigationBar title={ I18n.t('additional_fee.new') } sticky>
                    <FlexGroup end gap>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={ submitLoading }
                        >
                            <Translate value="save" />
                        </Button>
                    </FlexGroup>
                </NavigationBar>
                <div className="new-fee-form">
                    <FlexGroup className="config-block">
                        <FormGroup
                            className="fee-config-wrapper"
                            title={ I18n.t('additional_fee.details') }
                        >
                            <FlexGroup className="fee-claim-type-group">
                                <Input
                                    name={ CLAIM_TYPE }
                                    type={ TYPE_RADIO }
                                    caption="additional_fee.claim_type"
                                    value={ getClaimTypeOpts() }
                                    selected={ claimType }
                                    required
                                />
                            </FlexGroup>
                            <Input
                                name={ FEE_TYPE }
                                type={ TYPE_SELECT }
                                caption="additional_fee.fee_type"
                                value={ getFeeTypeOpts() }
                                selected={ feeType }
                                required
                            />
                            <Input
                                name={ FEE_SUBTYPE }
                                type={ TYPE_SELECT }
                                caption="additional_fee.subtype"
                                value={ getFeeSubtypeOpts() }
                                selected={ subtype }
                                disabled={ feeType === DEFAULT_FEE_TYPE }
                                required
                            />
                            <Input
                                name={ AMOUNT }
                                type={ TYPE_NUMBER }
                                caption="rental.price"
                                value={ amount }
                                required
                            />
                            <Input
                                name={ VIOLATE_ZONE }
                                type={ TYPE_SELECT }
                                caption="additional_fee.violate_zone"
                                value={ getViolateZoneOpts() }
                                selected={ violateZone }
                                required
                            />
                            {
                                feeType === FEE_TYPE_FINE ? (
                                    <Input
                                        name={ TICKET_NO }
                                        type={ TYPE_TEXT }
                                        caption="additional_fee.ticket_no"
                                        value={ ticketNo }
                                        required={ feeType === FEE_TYPE_FINE }
                                        maxLength={ 20 }
                                    />
                                ) : null
                            }
                            <Input
                                name={ ZENDESK_NO }
                                type={ TYPE_TEXT }
                                caption="additional_fee.zendesk_no"
                                value={ zendeskNo }
                            />
                        </FormGroup>
                        <FormGroup
                            className="attachment-wrapper"
                            title={ I18n.t('attachments') }
                            withBadge={ <span className="subtitle">{ I18n.t(`additional_fee.${ claimType === CLAIM_TYPE_NO_CLAIM ? 'attachments_interal' : 'attachments_external' }`)}</span>  }
                        >
                            <UploadAttachments
                                list={ attachments }
                                title={ file => file?.name }
                                onDelete={ deleteAttachment }
                                accept={ ACCEPTED_ATTACHMENTS_TYPE }
                                onUpdate={ handleAttachmentUpdate }
                                disabled={ submitLoading }
                                namePrefix={ ATTACHMENTS }
                            />
                        </FormGroup>
                        <FormGroup
                            className="comment-wrapper"
                            title={ I18n.t('additional_fee.comments') }
                            withBadge={ <span className="subtitle">{ I18n.t('additional_fee.comments_ps')}</span>  }
                        >
                            <Input
                                className="comments"
                                name={ COMMENTS }
                                type={ TYPE_TEXTAREA }
                                rows={ 4 }
                                value={ comments }
                                withCaption={ false }
                            />
                        </FormGroup>
                    </FlexGroup>
                    <FlexGroup className="search-block" alignStart>
                        <FormGroup
                            className="search-rental"
                            title={ I18n.t('additional_fee.search_title') }
                        >
                            <ViolateRental claimType={claimType } />
                        </FormGroup>
                    </FlexGroup>
                </div>
            </Form>
            <ClaimWarning
                show={ showClaimWarning }
                onClose={ closeClaimWarning }
                onSave={ handleCreateSubmit(false) }
                saveDisabled={ submitLoading }
            />
        </AuthView>
    );
};

export default NewAdditionalFee;
