import { useState, useCallback } from 'react';
import { store as rtkStore } from "redux/store";
import { rentalsApi } from "redux/services/rentalsApi";

export const useLazyGetRentalInvoiceQuery = (options) => {
    const { getRentalInvoice } = rentalsApi.endpoints;
    const [result, setResult] = useState();
    const fetch = useCallback(async (params) => {
        const response =  await rtkStore.dispatch(getRentalInvoice.initiate({ params }, options));
        setResult(response);
        return response;
    }, [options, getRentalInvoice]);


    return [
        fetch,
        result,
    ];
};

export const useReissueRentalInvoiceMutation = () => {
    const { reissueRentalInvoice } = rentalsApi.endpoints;
    const [result, setResult] = useState();
    const fetch = useCallback(async (params) => {
        const response =  await rtkStore.dispatch(reissueRentalInvoice.initiate({ params }));
        setResult(response);
        return response;
    }, [reissueRentalInvoice]);

    return [
        fetch,
        result,
    ];
};
