import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './utils';

const GET_RETRIEVAL = '/retrieval';

export const customerApi = createApi({
    reducerPath: 'customerApi',
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        getRetrival: builder.query({
            query: ({ order_id }) => ({
                url: GET_RETRIEVAL,
                params: { order_id }
            }),
            forceRefetch: () => true,
        }),
    })
});

export const {
    useLazyGetRetrivalQuery,
} = customerApi;
