import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { CardBody, Card } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Form/Button';
import Form from 'components/Form';
import Input, {
    TYPE_NUMBER,
} from 'components/Form/Input';

class ChangeAmountModal extends Component {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        discount: PropTypes.number,
        subTotal: PropTypes.number,
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
    };

    static defaultProps = {
        subTotal: 0,
        discount: 0,
        onClose: () => {},
        onSubmit: () => {},
    };

    constructor(props) {
        super(props);

        const { subTotal } = props;

        this.elPrice = React.createRef();
        this.couponId = undefined;
        this.state = {
            inProgress: false,
            newAmount: subTotal,
        };
    }

    componentDidUpdate(prevProps) {
        const { show, subTotal } = this.props;
        if (prevProps.show && !show) {
            this.setState({
                newAmount: subTotal,
            });
        }
    }

    handleSubmit = () => {
        const { onSubmit } = this.props;
        const price = (this.elPrice.current || {}).value;

        return onSubmit(price);
    }

    handleKeyPress = e => {
        e.key === 'Enter' && e.preventDefault();
    };

    render() {
        const { inProgress, newAmount } = this.state;
        const { onClose, discount, subTotal, show } = this.props;

        return show ? (
            <Modal
                className="view-documents-modal"
                title={ I18n.t('rental.change_amount') }
                onClose={ onClose }
                disabledCloseOnBg
            >
                <Form
                    inline
                    onSubmit={ this.handleSubmit }
                    onKeyPress={ this.handleKeyPress }
                    inProgress={ inProgress }
                >
                    <Card>
                        <CardBody>
                            <Input
                                assignedstyle="original-amount"
                                type={ TYPE_NUMBER }
                                caption="rental.original_amount"
                                value={ subTotal }
                                viewMode
                            />
                            <Input
                                step="1"
                                type={ TYPE_NUMBER }
                                innerRef={ this.elPrice }
                                caption="rental.new_amount"
                                min={ discount || 0 }
                                value={ newAmount }
                                required
                            />
                        </CardBody>
                    </Card>
                    <ButtonGroup>
                        <Button
                            type="submit"
                            color="primary"
                        >
                            <Translate value="apply" />
                        </Button>
                    </ButtonGroup>
                </Form>
            </Modal>
        ) : null;
    }
}

export default ChangeAmountModal;
