import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import ModalWrapper from 'components/ModalWrapper';
import { Translate, I18n } from 'react-redux-i18n';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Form/Button';

import './claim-warning.scss';

const ClaimWarning = ({
    show,
    onClose,
    onSave,
    saveDisabled,
}) => {
    const handleClose = e => {
        onClose();
    };
    return (
        <>
            {
                show ? (
                    <ModalWrapper>
                        <Modal
                            className="claim-warning-modal"
                            title={ I18n.t('additional_fee.confirm_save_claim_fee_title') }
                            disabledCloseOnBg
                            onClose={ handleClose }
                        >
                            <div className="message">
                                { I18n.t('additional_fee.confirm_save_claim_fee') }
                            </div>
                            <ButtonGroup>
                                <Button type="button" className="btn-cancel" onClick={ onClose } outline>
                                    <Translate value="cancel" />
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    onClick={ onSave }
                                    disabled={ saveDisabled }
                                >
                                    <Translate value="save" />
                                </Button>
                            </ButtonGroup>
                        </Modal>
                    </ModalWrapper>
                ) : null
            }
        </>
    );
};

ClaimWarning.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    saveDisabled: PropTypes.bool,
};
ClaimWarning.defaultProps = {
    show: false,
    onClose: () => {},
    onSave: () => {},
    saveDisabled: false,
};

export default ClaimWarning;
