import React from 'react';
import { Translate } from 'react-redux-i18n';
import Cell from 'components/ListView/Cell';
import { NavLink } from 'react-router-dom';
import buildActualPath from 'helpers/build-actual-path';
import AuthFeature from 'components/AuthFeature';
import {
    AUTH_VIEW_RENTAL_DETAIL,
    AUTH_VIEW_EXPENSE_DETAIL,
    AUTH_VIEW_CUSTOMER_DETAIL
} from 'constants/permission';
import {
    RENTAL_DETAIL,
    ADDITIONAL_FEE_DETAIL,
    CUSTOMER_DETAIL
} from 'constants/routes';
import {
    TRANSACTION_RENTAL,
    TRANSACTION_ADDITIONAL_FEE,
    TRANSACTION_TRAVEL_PASS,
    CARD_TYPE_VISA,
    CARD_TYPE_MASTERCARD,
    CARD_TYPE_JCB,
    CARD_TYPE_UNION_PAY,
    CARD_TYPE_AMEX,
    CARD_TYPE_DISCOVERY_CARD,
} from 'constants/retrieval';

const TransactionType = ({ type }) => {
    let el = null;
    switch (type) {
    case TRANSACTION_RENTAL:
    case TRANSACTION_ADDITIONAL_FEE:
    case TRANSACTION_TRAVEL_PASS:
        el = <Translate value={ `retrieval.transaction_${ type }` } />;
        break;
    default:
        el = <span>--</span>;
    };

    return (
        <>
            { el }
        </>
    );
};

const CardType = ({ type }) => {
    let el = null;
    switch (type) {
    case CARD_TYPE_VISA:
    case CARD_TYPE_MASTERCARD:
    case CARD_TYPE_JCB:
    case CARD_TYPE_UNION_PAY:
    case CARD_TYPE_AMEX:
    case CARD_TYPE_DISCOVERY_CARD:
        el = <Translate value={ `retrieval.card_type_${ type }` } />;
        break;
    default:
        el = <span>--</span>;
    };

    return (
        <>
            { el }
        </>
    );
};

const RouteType = ({value, type}) => {
    let el = null;
    switch (type) {
    case TRANSACTION_RENTAL:
        el = (
            <AuthFeature
                requiredList={ [AUTH_VIEW_RENTAL_DETAIL] }
                fallback={ () => value }
            >
                <NavLink to={ buildActualPath(RENTAL_DETAIL, { rentalId: value }) }>
                    { value }
                </NavLink>
            </AuthFeature>
        );
        break;
    case TRANSACTION_ADDITIONAL_FEE:
        el = (
            <AuthFeature
                requiredList={ [AUTH_VIEW_EXPENSE_DETAIL] }
                fallback={ () => value }
            >
                <NavLink to={ buildActualPath(ADDITIONAL_FEE_DETAIL, { expenseId: value }) }>
                    { value }
                </NavLink>
            </AuthFeature>
        );
        break;
    case TRANSACTION_TRAVEL_PASS:
        el = (
            <AuthFeature
                requiredList={ [AUTH_VIEW_CUSTOMER_DETAIL] }
                fallback={ () => value }
            >
                <NavLink to={ buildActualPath(CUSTOMER_DETAIL, { customerId: `${value}?fromRetrieval=true` }) }>
                    { value }
                </NavLink>
            </AuthFeature>
            );
        break;
    default:
        el = <span>--</span>;
        break;
    };

    return (
        <>
            { el }
        </>
    );
}

const ListRow = ({ columns, data }) => {
    const renderField = ({ data, name, width}) => {
        let el = null;
        switch (name) {
        case 'transaction_record':
            el = <RouteType value={ data.transaction_record } type={ data.transaction_type } />;
            break;
        case 'transaction_type':
            el = <TransactionType type={ data[name] } />;
            break;
        case 'credit_card_type':
            el = <CardType type={ data[name] } />;
            break;
        default:
            el = data[name] === '' || data[name] == null ? '--' : data[name];
            break;
        }
        return (
            <Cell
                key={ `${ data.order_id }-${ name }` }
                name={ name }
                style={ {
                    width,
                } }
            >
                { el }
            </Cell>
        );
    }

    return columns.map(({ key, width }) => {
        return renderField({ data, width, name: key });
    });
}

export default ListRow;
