import {
    TRANSACTION_RENTAL,
    TRANSACTION_ADDITIONAL_FEE,
    TRANSACTION_TRAVEL_PASS,
    CARD_TYPE_VISA,
    CARD_TYPE_MASTERCARD,
    CARD_TYPE_JCB,
    CARD_TYPE_UNION_PAY,
    CARD_TYPE_AMEX,
    CARD_TYPE_DISCOVERY_CARD,
} from 'constants/retrieval';

const retrieval = {
    'retrieval_finder': 'Transaction Finder',
    [`transaction_${TRANSACTION_RENTAL}`]: 'Rental',
    [`transaction_${TRANSACTION_ADDITIONAL_FEE}`]: 'Additional fee',
    [`transaction_${TRANSACTION_TRAVEL_PASS}`]: 'DAY PASS',
    [`card_type_${CARD_TYPE_VISA}`]: 'VISA',
    [`card_type_${CARD_TYPE_MASTERCARD}`]: 'MasterCard',
    [`card_type_${CARD_TYPE_JCB}`]: 'JCB',
    [`card_type_${CARD_TYPE_UNION_PAY}`]: 'UnionPay',
    [`card_type_${CARD_TYPE_AMEX}`]: 'American Express',
    [`card_type_${CARD_TYPE_DISCOVERY_CARD}`]: 'Discover Card',
    'rental_id': 'Rental No.',
    'order_id': 'EC Order No.',
    'rental_amount': 'Amount',
    'card_first': 'First 6 digits of credit card',
    'card_last': 'Last 4 digits of credit card',
    'type': 'Type',
    'issuer': 'Issuer',
    'card_type': 'Card Type'
};

export default retrieval;
