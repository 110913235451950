import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './utils';

const GET_TRAVEL_PASS= '/user_travel_passes/';

export const travelPassApi = createApi({
    reducerPath: 'travelPassApi',
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        getTravelPassInvoice: builder.query({
            query: ({ params: { id }}) => ({
                url: `${ GET_TRAVEL_PASS }${ id }/invoices`,
            }),
            forceRefetch: () => true,
        }),
        reissueTravelPassInvoice: builder.mutation({
            query: ({ params: { id, ...payload} }) => ({
                url: `${ GET_TRAVEL_PASS }${ id }/invoices`,
                method: 'POST',
                body: payload,
            }),
        })
    })
});

export const {
    useLazyGetTravelPassInvoiceQuery,
    useReissueTravelPassInvoiceMutation
} = travelPassApi;
