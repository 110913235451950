const datetimeFormat = {
    'date': 'YYYY-MM-DD',
    'time': 'HH:mm:ss',
    'long': 'YYYY-MM-DD HH:mm:ss',
    'utc': 'YYYY-MM-DDTHH:mm:ss',
};
const translations = {
    'lang': 'English',
    'km': 'km',
    'times': 'times',
    'm': 'm',
    'fleet_management': 'Fleet Management',
    'login': 'Log in',
    'logout': 'Logout',
    'refrsh': 'Refresh',
    'export': 'Export',
    'import': 'Import',
    'close': 'Close',
    'cancel': 'Cancel',
    'continue': 'Continue',
    'update_time': 'Update Time',
    'disabled': 'Distabled',
    'unknown': 'Unknown',
    'from': 'From',
    'to': 'To',
    'go': 'Go',
    'duration': 'Duration',
    'distance': 'Distance',
    'do_action': 'Do Action',
    'user_id': 'User ID',
    'id': 'ID',
    'id_no': 'ID No.',
    'id_verify': 'ID Verified',
    'license_no': 'License No.',
    'license_verify': 'License Verified',
    'birthday': 'Birthday',
    'email': 'Email',
    'total_orders': 'Total Trips',
    'credit_card_no': 'Credit Card',
    'payment_method': 'Payment Method',
    'join_date': 'Join Date',
    'success': 'Success',
    'fail': 'Fail',
    'unverified': 'Unverified',
    'readability_issue': 'Readability Issue',
    'document_not_match': 'Documents not Matching',
    'flawed': 'Flawed Document',
    'ok': 'OK',
    'login_failed': 'Login Failed',
    'update_failed': 'Update Failed',
    'search': 'Search',
    'reservation_time': 'Reservation Time',
    'start_time': 'Riding Start',
    'end_time': 'End Time',
    'price': 'Price',
    'payment_status': 'Payment Status',
    'edit': 'Edit',
    'save': 'Save',
    'apply': 'Apply',
    'yes': 'Yes',
    'no': 'No',
    'know': 'Yes, I know',
    'have': 'Yes',
    'not_have': 'No',
    'warning': 'Warning',
    'confirm': 'Confirm',
    'language_settings': 'Language',
    'browse_files': 'Browse',
    'choose_files': 'Choose File...',
    'choose_file': 'Choose File',
    'submit': 'Submit',
    'replace': 'Replace',
    'datetime_format': datetimeFormat,
    'total_count': 'Count',
    'general_error': 'Oops! Something Went Wrong',
    'search_a_place': 'Search for a place',
    'select_all': 'Select All',
    'total_selection': 'Selection',
    'rental_management': 'Rental Management',
    'clear': 'Clear',
    'clear_all': 'Clear All',
    'years': 'Years',
    'months': 'Months',
    'weeks': 'Weeks',
    'days': 'Days',
    'hours': 'Hours',
    'minutes': 'Minutes',
    'seconds': 'Seconds',
    'search_for': 'Search For',
    'empty': 'Empty',
    'preview': 'Preview',
    'without_permission_error_message': '沒有存取權限',
    'apiValidateError': 'Your session has expired. Please log in again.',
    'jump_to': '跳轉至',
    'digits': 'digits',
    'map_layer': 'Map Layer',
    'download': 'Download',
    'download＿tmpl': 'Download Template',
    'file_size_5MB_warning': 'File size should be smaller than 5 MB.',
    'file_type_csv_warning': 'File should be csv format',
    'please_login': 'Please log in again',
    'please_login_message': (
        'To keep your work secure, your session has timed out. ' +
        'To log back in, please refresh your browser, or hit Log In below.'
    ),
    'general_error_message': 'Oops! Something went wrong. Please try again.',
    'link': 'Link',
    'restore': 'Restore',
    'corp_warning': 'There are scooters belong to different companies.(%{companies})',
    'enable': 'Enable',
    'disable': 'Disable',
    'attachments': 'Attachments',
    'no_match': 'No results found',
    'no_file_uploaded': 'No files found',
    'reupload': 'Choose again',
    'no_access_right': 'No access right',
    'copy': 'Copy',
    'data_update_time': 'Data updated on',
    'more': 'View more',
    'current': 'Current',
    'action_field': 'Action',
};

export default translations;
