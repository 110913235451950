import { useState, useEffect, useCallback, useRef } from 'react';
import { store as rtkStore } from "redux/store";
import { additionalFeeApi } from "redux/services/additionalFeeApi";
import isEqual from 'react-fast-compare';
import { addListener } from '@reduxjs/toolkit';

export const useGetAdditionalFeeQuery = (params, config) => {
    const [resp, setResp] = useState(undefined);
    const { getAdditionalFee } = additionalFeeApi.endpoints;
    const [fetchConfig, setFetchConfig] = useState(config);
    const subRef = useRef();

    const fetch = useCallback(
      async () => {
        const { pollingInterval } = fetchConfig || 0;
        const pollingConfig = pollingInterval ? {
            subscribe: true,
            subscriptionOptions: {
                pollingInterval,
            },
        } : {};
        subRef?.current?.unsubscribe();
        subRef.current = rtkStore.dispatch(getAdditionalFee.initiate({ params }, {
            forceRefetch: true,
            ...fetchConfig,
            ...pollingConfig,
        }));
        const response = await subRef.current;
        setResp(response);

      },
      [params, fetchConfig, getAdditionalFee],
    );

    useEffect(() => {
        if(!isEqual(config, fetchConfig)) {
            setFetchConfig(config);
        }
    }, [config, fetchConfig]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    useEffect(() => {

        return () => {
            subRef?.current?.unsubscribe();

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const unsubscribe = rtkStore.dispatch(
            addListener({
                predicate: () => true,
                effect: async (action, listenerApi) => {
                    const { type, payload } = action;
                    const isFullfilled = type.includes(additionalFeeApi.reducerPath) && type.includes('fulfilled');
                    if (isFullfilled) {
                        const { queryCacheKey } = subRef.current;
                        const cache = await listenerApi.getState()[additionalFeeApi.reducerPath].queries;
                        const prevTime = resp?.fulfilledTimeStamp;
                        const currentTime = cache[queryCacheKey].fulfilledTimeStamp;
                        const isError = payload?.error;
                        if (!isError && currentTime && prevTime && currentTime > prevTime) {
                            setResp({
                                ...cache[queryCacheKey],
                                isLoading: false,
                            });
                        }
                    }
                },
            })
        );
        return () => {
            (typeof unsubscribe === 'function') && unsubscribe();
        }
    }, [resp]);

    return  {
        ...resp,
        refetch: fetch,
    };
};


export const useLazyGetViolateRentalQuery = (options) => {
    const { getViolateRental } = additionalFeeApi.endpoints;
    const [result, setResult] = useState();
    const fetch = useCallback(async (params) => {
        const response =  await rtkStore.dispatch(getViolateRental.initiate({ params }, options));
        setResult(response);
        return response;
    }, [options, getViolateRental]);


    return [
        fetch,
        result,
    ];
};

export const useUploadFeeAttachmentMutation = () => {
    const { uploadFeeAttachment } = additionalFeeApi.endpoints;
    const [result, setResult] = useState();
    const fetch = useCallback(async (params) => {
        const response =  await rtkStore.dispatch(uploadFeeAttachment.initiate({ params }));
        setResult(response);
        return response;
    }, [uploadFeeAttachment]);

    return [
        fetch,
        result,
    ];
};

export const useCreateExpenseMutation = () => {
    const { createExpense } = additionalFeeApi.endpoints;
    const fetch = useCallback(async (params) => {
        return await rtkStore.dispatch(createExpense.initiate({ params }));

    }, [createExpense]);

    return [
        fetch,
    ];
};

export const useGetAdditionalFeeDetailQuery = (params, config) => {
    const [resp, setResp] = useState(undefined);
    const { getAdditionalFeeDetail } = additionalFeeApi.endpoints;
    const [fetchConfig, setFetchConfig] = useState(config);
    const fetch = useCallback(
        async () => {
            const response = await rtkStore.dispatch(getAdditionalFeeDetail.initiate(params, {
                forceRefetch: true,
                ...fetchConfig,
            }));
            setResp(response);
        },
        [params, fetchConfig, getAdditionalFeeDetail],
    );

    useEffect(() => {
        if(!isEqual(config, fetchConfig)) {
            setFetchConfig(config);
        }
    }, [config, fetchConfig]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return  {
        ...resp,
        refetch: fetch,
    };
};

export const useUpdateAdditionalFeeDetailMutation = () => {
    const { updateAdditionalFeeDetail } = additionalFeeApi.endpoints;
    const [result, setResult] = useState();
    const fetch = useCallback(async (params) => {
        const response =  await rtkStore.dispatch(updateAdditionalFeeDetail.initiate({ params }));
        setResult(response);
        return response;
    }, [updateAdditionalFeeDetail]);

    return [
        fetch,
        result,
    ];
};

export const useRefundAdditionalFeeMutation = () => {
    const { refundAdditionalFee } = additionalFeeApi.endpoints;
    const [result, setResult] = useState();
    const fetch = useCallback(async (params) => {
        const response =  await rtkStore.dispatch(refundAdditionalFee.initiate({ params }));
        setResult(response);
        return response;
    }, [refundAdditionalFee]);

    return [
        fetch,
        result,
    ];
};


export const useGetFeeAttachmentsMutation = () => {
    const { getFeeAttachments } = additionalFeeApi.endpoints;
    const [result, setResult] = useState();
    const fetch = useCallback(async (params) => {
        const response =  await rtkStore.dispatch(getFeeAttachments.initiate({ params }));
        setResult(response);
        return response;
    }, [getFeeAttachments]);

    return [
        fetch,
        result,
    ];
};

export const useLazyGetAdditionalFeeInvoiceQuery = (options) => {
    const { getAdditionalFeeInvoice } = additionalFeeApi.endpoints;
    const [result, setResult] = useState();
    const fetch = useCallback(async (params) => {
        const response =  await rtkStore.dispatch(getAdditionalFeeInvoice.initiate({ params }, options));
        setResult(response);
        return response;
    }, [options, getAdditionalFeeInvoice]);


    return [
        fetch,
        result,
    ];
};


export const useReissueAdditionalFeeInvoiceMutation = () => {
    const { reissueAdditionalFeeInvoice } = additionalFeeApi.endpoints;
    const [result, setResult] = useState();
    const fetch = useCallback(async (params) => {
        const response =  await rtkStore.dispatch(reissueAdditionalFeeInvoice.initiate({ params }));
        setResult(response);
        return response;
    }, [reissueAdditionalFeeInvoice]);

    return [
        fetch,
        result,
    ];
};
