import { useState, useCallback } from 'react';
import { store as rtkStore } from "redux/store";
import { customerApi } from "redux/services/customerApi";

export const useLazyGetRetrivalQuery = (options) => {
    const { getRetrival } = customerApi.endpoints;
    const [result, setResult] = useState();
    const fetch = useCallback(async (params) => {
        const response = await rtkStore.dispatch(getRetrival.initiate(params, options));
        setResult(response);
        return response
    }, [options, getRetrival]);

    return  [
        fetch,
        result
    ];
};

