import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Translate, I18n } from 'react-redux-i18n';
import { toggleErrorDialog } from 'actions';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import Input from 'components/Form/Input';
import { TYPE_RADIO, TYPE_TEXT } from 'components/Form/Input/input-type';
import ButtonGroup from 'components/ButtonGroup';
import FormGroup from 'components/Form/FormGroup';
import PropTypes from 'prop-types';
import ModalWrapper from 'components/ModalWrapper';
import Modal from 'components/Modal';
import FlexGroup from 'components/FlexGroup';
import Datetime from 'components/Datetime';
import serialize from 'form-serialize';
import AuthFeature from 'components/AuthFeature';
import { Badge } from 'reactstrap';
import {
    AUTH_VIEW_RENTAL_DETAIL,
    AUTH_VIEW_EXPENSE_DETAIL,
    AUTH_VIEW_USER_TRAVEL_PASS_DETAIL,
    AUTH_REISSUE_RENTAL_INVOICE,
    AUTH_REISSUE_EXPENSE_INVOICE,
    AUTH_REISSUE_USER_TRAVEL_PASS_INVOICE,
} from 'constants/permission';
import {
    INVOICE_TYPE_GSH,
    INVOICE_TYPE_UBN,
} from 'constants/rental';
import {
    useLazyGetRentalInvoiceQuery,
    useReissueRentalInvoiceMutation,
} from 'redux/hook/useRental';
import {
    useLazyGetTravelPassInvoiceQuery,
    useReissueTravelPassInvoiceMutation
} from 'redux/hook/useTravelPass';
import {
    useLazyGetAdditionalFeeInvoiceQuery,
    useReissueAdditionalFeeInvoiceMutation,
} from 'redux/hook/useAdditionalFee';

import './reissue-invoice.scss';
import { useCallback } from 'react';



const INVOICE_TYPE = 'invoice_type';
const INVOICE_UBN_CODE = 'invoice_type_value';
const INVOICE_UBN_TITLE = 'ubn_title';

const AUTH_REISSUE_FORM = {
    [AUTH_VIEW_RENTAL_DETAIL]: AUTH_REISSUE_RENTAL_INVOICE,
    [AUTH_VIEW_EXPENSE_DETAIL]: AUTH_REISSUE_EXPENSE_INVOICE,
    [AUTH_VIEW_USER_TRAVEL_PASS_DETAIL]: AUTH_REISSUE_USER_TRAVEL_PASS_INVOICE,
};

const ReissueInvoice = ({
    id,
    entryType,
    isValid,
    price,
    btnText,
    onSuccess,
}) => {

    const dispatch = useDispatch();
    const i18n = useSelector(state => state.i18n);
    const [isShowModal, setIsShowModal] = useState(false);
    const [allowReissue, setAllowReissue] = useState(false);
    const [invoiceType, setInvoiceType] = useState();
    const [UBNData, setUBNData] = useState();
    const [invoiceHistory, setInvoiceHistory] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentInvoice, setCurrentInvoice] = useState();
    const formRef = useRef();
    const elUBNCode = useRef();
    const elUBNTitle = useRef();

    const REISSUE_INVOICE_TYPE_LIST = [
        {
            name: I18n.t(`rental.invoice_type.${ INVOICE_TYPE_GSH }`),
            value: INVOICE_TYPE_GSH,
        },
        {
            name:  I18n.t(`rental.invoice_type.${ INVOICE_TYPE_UBN }`),
            value: INVOICE_TYPE_UBN,

        }
    ];

    const [getRentalInvoice] = useLazyGetRentalInvoiceQuery();
    const [getTravelPassInvoice] = useLazyGetTravelPassInvoiceQuery();
    const [getAdditionalFeeInvoice] = useLazyGetAdditionalFeeInvoiceQuery();

    const [reissueRentalInvoice] = useReissueRentalInvoiceMutation();
    const [reissueTravelPassInvoice] = useReissueTravelPassInvoiceMutation();
    const [reissueAdditionalFeeInvoice] = useReissueAdditionalFeeInvoiceMutation();


    const handleClose = () => {
        setIsShowModal(false);
        setInvoiceType();
        setUBNData();
        setInvoiceHistory([]);
        setIsSubmitting(false);
        setCurrentInvoice();
    };

    const handleClick = async() => {
        if (isSubmitting) return;
        let result = {};
        switch(entryType) {
            case AUTH_VIEW_RENTAL_DETAIL:
                result = await getRentalInvoice({ id });
                break;
            case AUTH_VIEW_EXPENSE_DETAIL:
                result = await getAdditionalFeeInvoice({ id });
                break;
            case AUTH_VIEW_USER_TRAVEL_PASS_DETAIL:
                result = await getTravelPassInvoice({ id });
                break;
            default:
                result = null;
        }
        if (result === null) return;

        const { data, error } = result;
        const { code } = error?.data ?? {};

        if (error) {
            const message = (code === 104)? I18n.t('rental.invoice_empty') : I18n.t('general_error_message')
            dispatch(toggleErrorDialog(
                I18n.t(message),
            ));
        }
        else {
            const { invoice_data, user_invoice_setting } = data;
            const { ubn, ubn_title } = user_invoice_setting;
            setInvoiceHistory(invoice_data);
            setUBNData({
                ubn,
                ubn_title,
            });

            setIsShowModal(true);
        }

    };

    const handleInvoiceTypeChange = value => {

        setInvoiceType(value[0]);

    };
    const handleFormSubmit = () => {
        if (!isSubmitting && currentInvoice?.invoice_number) {
            setIsSubmitting(true);
            handleReissue();
        }
    };

    const handleReissue = useCallback(async() => {

        let result = {};
        const formData = serialize(formRef.current, { hash: true });
        const invoiceType = Number(formData[INVOICE_TYPE]);
        let params = {
            id,
            invoice_id: currentInvoice.invoice_id,
            [INVOICE_TYPE]: invoiceType,
            [INVOICE_UBN_CODE]: invoiceType === INVOICE_TYPE_GSH ? undefined : elUBNCode?.current?.value,
            [INVOICE_UBN_TITLE]: invoiceType === INVOICE_TYPE_GSH ? undefined : elUBNTitle?.current?.value,
        };

        switch(entryType) {
            case AUTH_VIEW_RENTAL_DETAIL:
                result = await reissueRentalInvoice(params);
                break;
            case AUTH_VIEW_EXPENSE_DETAIL:
                result = await reissueAdditionalFeeInvoice(params);
                break;
            case AUTH_VIEW_USER_TRAVEL_PASS_DETAIL:
                result = await reissueTravelPassInvoice(params);
                break;
            default:
                result = null;
        }
        const { error } = result || {};
        const { code } = error?.data ?? {};
        let message =  I18n.t('general_error_message');


        if (error) {
            switch (code) {
                case 102:
                    message = I18n.t('rental.invoice_is_issuing');
                    break;
                case 103:
                    message = I18n.t('rental.invoice_note_fail');
                    break;
                case 105:
                    message = I18n.t('rental.invoice_uncharged_error');
                    break;
                case 101:
                    message = I18n.t('rental.invoice_ubn_format_error');
                    break;
                default:
                    message = I18n.t('general_error_message');
            }
            setIsShowModal(false);
            dispatch(toggleErrorDialog(I18n.t(message), handleClose));
            return;
        }
        else {
            onSuccess();
            handleClose();
        }

    }, [entryType, id, currentInvoice, reissueAdditionalFeeInvoice, reissueRentalInvoice, reissueTravelPassInvoice, dispatch, onSuccess]);

    useEffect(() => { }, [i18n]);

    useEffect(() => {
        const isAllowed = (isValid && price > 0);
        setAllowReissue(isAllowed);
    }, [isValid, price]);

    useEffect(() => {
        if (invoiceHistory.length) {
            const current = invoiceHistory.filter(({ is_current }) => is_current);

            setCurrentInvoice(current[0]);
        }
        else {
            setCurrentInvoice();
        }
    }, [invoiceHistory]);


    return (
        <AuthFeature requiredList={ [entryType] } >
            <>
                {
                    allowReissue ? (
                    <Button
                        className="reissue-invoice-btn"
                        type="button"
                        outline
                        onClick={ handleClick }
                        disabled={ isShowModal }
                    >
                        <Translate value={ btnText } />
                    </Button>
                    ) : null
                }
            </>
            <>
                {
                    isShowModal ? (
                        <ModalWrapper>
                            <Modal
                                className="reissue-invoice-modal"
                                title={ I18n.t('rental.reissue_invoice_title') }
                                disabledCloseOnBg
                                onClose={ handleClose }
                            >
                                <AuthFeature requiredList={ [AUTH_REISSUE_FORM[entryType]] } >
                                    <Form
                                        innerRef={ formRef }
                                        className="reissue-invoice-form"
                                        stopSubmitByEnter
                                        inProgress={ isSubmitting }
                                        onSubmit={ handleFormSubmit }
                                    >
                                        <FormGroup title="" className="reissue-invoice-fields" >
                                            <Input
                                                name={ INVOICE_TYPE }
                                                assignedstyle="reissue-invoice-type"
                                                type={ TYPE_RADIO }
                                                value={ REISSUE_INVOICE_TYPE_LIST }
                                                caption="rental.reissue_invoice_type"
                                                selected={ invoiceType }
                                                onChange={ handleInvoiceTypeChange }
                                                required
                                            />
                                            {
                                                (invoiceType === INVOICE_TYPE_UBN) ? (
                                                    <>
                                                        <Input
                                                            name={ INVOICE_UBN_CODE }
                                                            type={ TYPE_TEXT }
                                                            innerRef={ elUBNCode }
                                                            value={ UBNData.ubn }
                                                            caption="rental.invoice_ubn_code"
                                                            required={ invoiceType === INVOICE_TYPE_UBN }
                                                        />
                                                        <Input
                                                            name={ INVOICE_UBN_TITLE }
                                                            innerRef={ elUBNTitle }
                                                            type={ TYPE_TEXT }
                                                            value={ UBNData.ubn_title }
                                                            caption="rental.invoice_ubn_title"
                                                            required={ invoiceType === INVOICE_TYPE_UBN }
                                                        />
                                                    </>
                                                ) : null
                                            }
                                        </FormGroup>
                                        <ButtonGroup>
                                            <Button type="button" className="btn-cancel" outline onClick={ handleClose } disabled={ isSubmitting || !currentInvoice?.invoice_number}>
                                                <Translate value="cancel" />
                                            </Button>
                                            <Button color="primary" type="submit" disabled={ isSubmitting || !currentInvoice?.invoice_number }>
                                                <Translate value="submit" />
                                            </Button>
                                        </ButtonGroup>
                                    </Form>
                                </AuthFeature>
                                <FlexGroup className="invoice-list-section" alignStart>
                                    <Translate className="title" value="rental.invoice_list_title" tag="h3" />
                                    {
                                        invoiceHistory.length ? (
                                            <ul className="invoice-list">
                                                {
                                                    invoiceHistory.map(item => (
                                                        <li className="invoice-item" key={ item.invoice_id ?? id  }>
                                                            <Datetime
                                                                className="invoice-date"
                                                                time={ item.invoice_create_time ?? '' }
                                                                format={ I18n.t('datetime_format.date') }
                                                            />
                                                            {
                                                                item.invoice_number ? (
                                                                    <span>
                                                                        <strong>{ item.invoice_number }</strong>
                                                                        { ` (${ I18n.t('rental.invoice_random_code') }: ${ item.random_number })` }
                                                                    </span>
                                                                ) : (
                                                                    <>
                                                                        <span>--</span>
                                                                        <Translate value="rental.invoice_issuing" />
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                item.is_current ? (
                                                                    <Badge color="success">
                                                                        <Translate value="current" />
                                                                    </Badge>
                                                                ) : null
                                                            }
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        ) : null
                                    }
                                </FlexGroup>
                            </Modal>
                        </ModalWrapper>
                    ) : null
                }
            </>
        </AuthFeature>
    );
};

ReissueInvoice.propTypes = {
    id: PropTypes.string.isRequired,
    entryType: PropTypes.oneOf([
        AUTH_VIEW_RENTAL_DETAIL,
        AUTH_VIEW_EXPENSE_DETAIL,
        AUTH_VIEW_USER_TRAVEL_PASS_DETAIL]).isRequired,
    isValid: PropTypes.bool,
    price: PropTypes.number,
    btnText: PropTypes.string,
    onSuccess: PropTypes.func,
};
ReissueInvoice.defaultProps = {
    isValid: false,
    price: 0,
    btnText: 'more',
    onSuccess: () => {},
};

export default ReissueInvoice;
