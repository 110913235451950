import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { CardBody, Card } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Form/Button';
import Form from 'components/Form';
import Input, {
    TYPE_NUMBER,
    TYPE_RADIO,
} from 'components/Form/Input';

const FULL_REFUND = 0;
const PARTIAL_REFUND = 1;

class DayPassRefundModal extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        show: PropTypes.bool.isRequired,
        item: PropTypes.shape({}),
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        title: PropTypes.string,
    };

    static defaultProps = {
        item: { price: 0 },
        onClose: () => {},
        onSubmit: () => {},
        title: '',
    };

    constructor(props) {
        super(props);

        this.couponId = undefined;
        this.state = {
            inProgress: false,
            refundType: -1,
            refundValue: 0,
        };
    }

    handleSubmit = () => {
        const { onSubmit } = this.props;
        const { refundValue } = this.state;

        return onSubmit(refundValue);
    }

    handleClose = () => {
        const { onClose } = this.props;
        onClose();
    }

    handleKeyPress = e => {
        e.key === 'Enter' && e.preventDefault();
    };

    handleRefundTypeChange = refund => {
        const { item } = this.props;
        const { price } = item;
        this.setState({
            refundType: refund[0],
            refundValue: refund[0] === FULL_REFUND ? price : 0,
        });
    }

    handleRefundValueChange = event => {
        const value = event.target.value;
        this.setState({
            refundValue: value,
        });
    }

    componentDidUpdate(prevProps) {
        const { show } = this.props;
        if (prevProps.show && !show) {
            this.setState({
                refundType: -1,
                refundValue: 0,
            });
        }
    }

    render() {
        const { inProgress, refundType, refundValue } = this.state;
        const { item, show, title } = this.props;
        const { price } = item;

        const isInvalid = refundType === -1
            || refundValue === ''
            || refundValue < 1
            || refundValue > price
            || !Number.isInteger(Number(refundValue));

        return show ? (
            <Modal
                className="view-documents-modal"
                title={ title || I18n.t('rental.refund') }
                onClose={ this.handleClose }
                disabledCloseOnBg
            >
                <Form
                    inline
                    onSubmit={ this.handleSubmit }
                    onKeyPress={ this.handleKeyPress }
                    inProgress={ inProgress }
                >
                    <Card>
                        <CardBody>
                            <Input
                                assignedstyle="result-input-total"
                                type={ TYPE_NUMBER }
                                caption="rental.total_charged"
                                value={ price || 0 }
                                viewMode
                            />
                            <>
                                <Input
                                    name="refund"
                                    assignedstyle="refund-type"
                                    type={ TYPE_RADIO }
                                    value={ [{
                                        name: <div className="full-refund">{ I18n.t('customer.full_refund') }</div>,
                                        value: FULL_REFUND,
                                        translate: true,
                                    }, {
                                        name: (
                                            <div className="partial-refund">
                                                <span className="partial-refund-text">{ I18n.t('customer.partial_refund') }</span>
                                                    <Input
                                                        disabled={ refundType !== PARTIAL_REFUND}
                                                        type={ TYPE_NUMBER }
                                                        min="0"
                                                        max={ price || 0 }
                                                        unitCaption={ I18n.t('customer.dollars') }
                                                        onChange={ this.handleRefundValueChange }
                                                        value={ refundType === FULL_REFUND ? 0 : refundValue }
                                                    />
                                            </div>
                                        ),
                                        value: PARTIAL_REFUND,
                                        translate: true,
                                    }] }
                                    caption="rental.refund"
                                    onChange={ this.handleRefundTypeChange }
                                    selected={ refundType }
                                />
                            </>
                        </CardBody>
                    </Card>
                    <div className="refund-result">
                        <Input
                            assignedstyle="result-input"
                            type={ TYPE_NUMBER }
                            caption="customer.original_charge"
                            value={ price }
                            viewMode
                        />
                        <Input
                            assignedstyle="result-input result-refund"
                            type={ TYPE_NUMBER }
                            caption="rental.refund"
                            value={ isInvalid ? '--' : parseInt(refundValue) }
                            viewMode
                        />
                        <Input
                            assignedstyle="result-input"
                            type={ TYPE_NUMBER }
                            caption="customer.final_charge"
                            value={ isInvalid ? '--' : (price - refundValue) }
                            viewMode
                        />
                    </div>
                    <ButtonGroup>
                        <Button
                            disabled={ isInvalid }
                            type="button"
                            color="primary"
                            onClick={ this.handleClose }
                            outline
                        >
                            <Translate value="cancel" />
                        </Button>
                        <Button
                            disabled={ isInvalid }
                            type="submit"
                            color="primary"
                        >
                            <Translate value="apply" />
                        </Button>
                    </ButtonGroup>
                </Form>
            </Modal>
        ) : null;
    }
}

export default DayPassRefundModal;
