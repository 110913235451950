/**
 * Permission
 */
// rental
export const AUTH_VIEW_RENTAL_LIST = 'VIEW_RENTAL_LIST';
export const AUTH_VIEW_RENTAL_FINDER = 'VIEW_RENTAL_FINDER';
export const AUTH_VIEW_RENTAL_DETAIL = 'VIEW_RENTAL_DETAIL';
export const AUTH_REFUND_RENTAL = 'REFUND_RENTAL';
export const AUTH_UPDATE_RENTAL_SUBTOTAL = 'UPDATE_RENTAL_SUBTOTAL';

// customer
export const AUTH_VIEW_CUSTOMER_FINDER = 'VIEW_CUSTOMER_FINDER';
export const AUTH_VIEW_CUSTOMER_DETAIL = 'VIEW_CUSTOMER_DETAIL';
export const AUTH_VIEW_CUSTOMER_DETAIL_WITH_VERIFY = 'VIEW_CUSTOMER_DETAIL_WITH_VERIFY';
export const AUTH_READ_CUSTOMER_MEMBERSHIP_INFO = 'READ_CUSTOMER_MEMBERSHIP_INFO';
export const AUTH_READ_CUSTOMER_PERSONAL_INFO = 'READ_CUSTOMER_PERSONAL_INFO';
export const AUTH_READ_CUSTOMER_DOCUMENT_INFO = 'READ_CUSTOMER_DOCUMENT_INFO';
export const AUTH_READ_CUSTOMER_DOCUMENT_PHOTO = 'READ_CUSTOMER_DOCUMENT_PHOTO';
export const AUTH_EDIT_CUSTOMER_EMAIL = 'EDIT_CUSTOMER_EMAIL';
export const AUTH_EDIT_CUSTOMER_PHONE = 'EDIT_CUSTOMER_PHONE';
export const AUTH_EDIT_CUSTOMER_ATTRIBUTE = 'EDIT_CUSTOMER_ATTRIBUTE';
export const AUTH_EDIT_CUSTOMER_DOCUMENT_INFO = 'EDIT_CUSTOMER_DOCUMENT_INFO';
export const AUTH_ENABLE_CUSTOMER = 'ENABLE_CUSTOMER';
export const VIEW_CUSTOMER_GOV_ADDI_PURCHASE_LIST = 'VIEW_CUSTOMER_GOV_ADDI_PURCHASE_LIST';
export const AUTH_FIND_CUSTOMER_BY_ID_NUMBER = 'FIND_CUSTOMER_BY_ID_NUMBER';
export const AUTH_RENT_SUSPEND = 'RENT_SUSPEND';
export const AUTH_VIEW_CUSTOMER_DETAIL_PRIVACY = 'VIEW_CUSTOMER_DETAIL_PRIVACY';
export const AUTH_VIEW_CUSTOMER_TRAVEL_PASS_LIST = 'VIEW_CUSTOMER_TRAVEL_PASS_LIST';
export const AUTH_REFUND_CUSTOMER_TRAVEL_PASS = 'REFUND_CUSTOMER_TRAVEL_PASS';
export const AUTH_VIEW_RETRIEVAL_FINDER = 'VIEW_RETRIEVAL_FINDER';
export const AUTH_VIEW_USER_TRAVEL_PASS_DETAIL = 'VIEW_USER_TRAVEL_PASS_DETAIL';

// scooter
export const AUTH_VIEW_SCOOTER_LIST = 'VIEW_SCOOTER_LIST';
export const AUTH_VIEW_SCOOTER_FINDER = 'VIEW_SCOOTER_FINDER';
export const AUTH_VIEW_SCOOTER_DETAIL = 'VIEW_SCOOTER_DETAIL';
export const AUTH_EDIT_SCOOTER_TAG = 'EDIT_SCOOTER_TAG';
export const AUTH_EDIT_TASK_ASSIGNEE = 'EDIT_TASK_ASSIGNEE';
export const AUTH_EDIT_TASK_DESCRIPTION = 'EDIT_TASK_DESCRIPTION';
export const AUTH_FINISH_TASK = 'FINISH_TASK';
export const AUTH_SAVE_TASK_CHANGE = 'SAVE_TASK_CHANGE';
export const AUTH_CONTROL_SCOOTER = 'CONTROL_SCOOTER';
export const AUTH_FIND_BCU_SCOOTER = 'FIND_BCU_SCOOTER';
export const AUTH_DO_SCOOTER_ONLINE = 'DO_SCOOTER_ONLINE';
export const AUTH_DO_SCOOTER_OFFLINE = 'DO_SCOOTER_OFFLINE';
export const AUTH_FORCE_RETURN_SCOOTER = 'FORCE_RETURN_SCOOTER';
export const AUTH_UPDATE_SCOOTER_GTU = 'UPDATE_SCOOTER_GTU';
export const AUTH_VIEW_GTU_LIST = 'VIEW_GTU_LIST';
export const AUTH_CHECK_ILLEGAL_PARKING = 'CHECK_ILLEGAL_PARKING';
export const AUTH_CONTROL_SCOOTER_WITH_TICKET = 'CONTROL_SCOOTER_WITH_TICKET';

// banner
export const AUTH_VIEW_BANNER_LIST = 'VIEW_BANNER_LIST';
export const AUTH_VIEW_BANNER_DETAIL = 'VIEW_BANNER_DETAIL';
export const AUTH_EDIT_BANNER = 'EDIT_BANNER';
export const AUTH_PUBLISH_BANNER = 'PUBLISH_BANNER';
export const AUTH_WITHDRAW_BANNER = 'WITHDRAW_BANNER';

// coupon
export const AUTH_VIEW_COUPON_LIST = 'VIEW_COUPON_LIST';
export const AUTH_VIEW_COUPON_FINDER = 'VIEW_COUPON_FINDER';
export const AUTH_VIEW_COUPON_DETAIL = 'VIEW_COUPON_DETAIL';
export const AUTH_EDIT_COUPON = 'EDIT_COUPON';
export const AUTH_PUBLISH_COUPON = 'PUBLISH_COUPON';
export const AUTH_WITHDRAW_COUPON = 'WITHDRAW_COUPON';
export const AUTH_VIEW_AVAILABLE_SYSTEM_COUPON_LIST = 'VIEW_AVAILABLE_SYSTEM_COUPON_LIST';

// GoStation
export const VIEW_VM_LIST = 'VIEW_VM_LIST';

// log
export const AUTH_VIEW_AUDIT_LOG_LIST = 'VIEW_AUDIT_LOG_LIST';

// ticket
export const AUTH_CREATE_TICKET = 'CREATE_TICKET';
export const AUTH_EDIT_TICKET = 'EDIT_TICKET';
export const AUTH_GET_TICKET = 'GET_TICKET';
export const AUTH_GET_TICKET_LIST = 'GET_TICKET_LIST';
export const AUTH_GET_TICKET_SELF_LIST = 'GET_TICKET_SELF_LIST';
export const AUTH_BATCH_EDIT_TICKET = 'BATCH_EDIT_TICKET';
export const AUTH_VIEW_RESTRICT_TICKET_ASSIGNEE = 'VIEW_RESTRICT_TICKET_ASSIGNEE';

// admin
export const AUTH_VIEW_FLEET_USER_LIST = 'VIEW_FLEET_USER_LIST';
export const AUTH_EDIT_FLEET_USER = 'EDIT_FLEET_USER';
export const AUTH_EDIT_FLEET_USER_FRANCHISEE = 'EDIT_FLEET_USER_FRANCHISE';
export const AUTH_VIEW_FLEET_USER_ROLE = 'VIEW_FLEET_USER_ROLE';

// promotion
export const AUTH_VIEW_PROMOTION_EVENT_LIST = 'VIEW_PROMOTION_EVENT_LIST';
export const AUTH_EDIT_PROMOTION_EVENT = 'EDIT_PROMOTION_EVENT';
export const AUTH_VIEW_PROMOTION_EVENT_DETAIL = 'VIEW_PROMOTION_EVENT_DETAIL';
export const AUTH_PUBLISH_PROMOTION_EVENT = 'PUBLISH_PROMOTION_EVENT';

// violate
export const VIEW_VIOLATE_RENTAL_LIST = 'VIEW_VIOLATE_RENTAL_LIST';

// mission
export const AUTH_VIEW_MISSION_LIST = 'VIEW_MISSION_LIST';
export const AUTH_EDIT_MISSION = 'EDIT_MISSION';
export const AUTH_PUBLISH_MISSION = 'PUBLISH_MISSION';
export const AUTH_WITHDRAW_MISSION = 'WITHDRAW_MISSION';
export const AUTH_VIEW_MISSION_DETAIL = 'VIEW_MISSION_DETAIL';

// asset
export const AUTH_VIEW_ASSET_LIST = 'VIEW_ASSET_LIST';
export const AUTH_EDIT_ASSET = 'EDIT_ASSET';

// dashboard
export const AUTH_VIEW_DASHBOARD = 'VIEW_DASHBOARD';

// regular maintenance
export const AUTH_VIEW_SCOOTER_MAINTAIN_LIST = 'VIEW_SCOOTER_MAINTAIN_LIST';

// Additional Fee
export const AUTH_VIEW_EXPENSE_LIST = 'VIEW_EXPENSE_LIST';
export const AUTH_VIEW_CUSTOMER_EXPENSE_LIST = 'VIEW_CUSTOMER_EXPENSE_LIST';
export const AUTH_CREATE_EXPENSE = 'CREATE_EXPENSE';
export const AUTH_EDIT_EXPENSE = 'EDIT_EXPENSE';
export const AUTH_VIEW_EXPENSE_DETAIL = 'VIEW_EXPENSE_DETAIL';
export const AUTH_REFUND_EXPENSE = 'REFUND_EXPENSE';
export const AUTH_ABSORB_EXPENSE = 'ABSORB_EXPENSE';
export const AUTH_REVOKE_EXPENSE = 'REVOKE_EXPENSE';

// Reissue Invoice
export const AUTH_REISSUE_RENTAL_INVOICE = 'REISSUE_RENTAL_INVOICE';
export const AUTH_REISSUE_EXPENSE_INVOICE = 'REISSUE_EXPENSE_INVOICE';
export const AUTH_REISSUE_USER_TRAVEL_PASS_INVOICE = 'REISSUE_USER_TRAVEL_PASS_INVOICE';
