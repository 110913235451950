import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { dashboardApi } from './services/dashboardApi';
import { authApi } from './services/authApi';
import { additionalFeeApi } from './services/additionalFeeApi';
import { violationApi } from './services/violationApi';
import { scooterApi } from './services/scooterApi';
import { rentalsApi } from './services/rentalsApi';
import { travelPassApi } from './services/travelPassApi';
import { customerApi } from './services/customerApi';
import authReducer from './features/authSlice';

// This is for custom hook with polling listeners
const subscriptionListenerMiddleware = createListenerMiddleware();

export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        [dashboardApi.reducerPath]: dashboardApi.reducer,
        [additionalFeeApi.reducerPath]: additionalFeeApi.reducer,
        [violationApi.reducerPath]: violationApi.reducer,
        [scooterApi.reducerPath]: scooterApi.reducer,
        [rentalsApi.reducerPath]: rentalsApi.reducer,
        [travelPassApi.reducerPath]: travelPassApi.reducer,
        [customerApi.reducerPath]: customerApi.reducer,
        auth: authReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat([
            authApi.middleware,
            dashboardApi.middleware,
            additionalFeeApi.middleware,
            violationApi.middleware,
            scooterApi.middleware,
            rentalsApi.middleware,
            travelPassApi.middleware,
            customerApi.middleware,
        ])
        .prepend(subscriptionListenerMiddleware.middleware),
});

