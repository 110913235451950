export const TRANSACTION_RENTAL = 0;
export const TRANSACTION_ADDITIONAL_FEE = 1;
export const TRANSACTION_TRAVEL_PASS = 2;

export const CARD_TYPE_VISA = 1;
export const CARD_TYPE_MASTERCARD = 2;
export const CARD_TYPE_JCB = 3;
export const CARD_TYPE_UNION_PAY = 4;
export const CARD_TYPE_AMEX = 5;
export const CARD_TYPE_DISCOVERY_CARD = 6;
