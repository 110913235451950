import {
    SCOOTER_LIST,
    SCOOTER_MAP,
    SCOOTER_PROFILE,
    SCOOTER_SEARCH,
    RENTAL_MANAGEMENT,
    RENTAL_DETAIL,
    COUPON_HOME,
    SYSTEM_COUPON,
    NEW_COUPON,
    NEW_SYSTEM_COUPON,
    EDIT_COUPON,
    EDIT_SYSTEM_COUPON,
    AD_MANAGEMENT,
    NEW_AD,
    EDIT_AD,
    ORDER_AD,
    PROMOTION_MANAGEMENT,
    NEW_PROMOTION,
    PROMOTION_DETAIL,
    MISSION_MANAGEMENT,
    NEW_MISSION,
    MISSION_DETAIL,
    ORDER_MISSION,
    CUSTOMER_FINDER,
    CUSTOMER_DETAIL,
    TICKET_LIST,
    TICKET_DETAIL,
    MY_TICKET,
    LOG_RENTAL,
    TICKET_ASSIGNMENT,
    ADMIN,
    VIOLATION_DETAIL,
    ASSET_MANAGEMENT,
    DASHBOARD,
    TV_MONITOR,
    REGULAR_MAINTENANCE,
    ADDITIONAL_FEE,
    NEW_ADDITIONAL_FEE,
    ADDITIONAL_FEE_DETAIL,
} from 'constants/routes';
import {
    AUTH_VIEW_CUSTOMER_FINDER,
    AUTH_VIEW_CUSTOMER_DETAIL,
    AUTH_VIEW_SCOOTER_LIST,
    AUTH_VIEW_SCOOTER_FINDER,
    AUTH_VIEW_SCOOTER_DETAIL,
    AUTH_VIEW_BANNER_LIST,
    AUTH_VIEW_BANNER_DETAIL,
    AUTH_EDIT_BANNER,
    AUTH_VIEW_COUPON_LIST,
    AUTH_VIEW_COUPON_DETAIL,
    AUTH_EDIT_COUPON,
    AUTH_VIEW_RENTAL_LIST,
    AUTH_VIEW_RENTAL_DETAIL,
    AUTH_VIEW_AUDIT_LOG_LIST,
    AUTH_GET_TICKET_LIST,
    AUTH_GET_TICKET,
    AUTH_GET_TICKET_SELF_LIST,
    AUTH_VIEW_FLEET_USER_LIST,
    AUTH_VIEW_PROMOTION_EVENT_LIST,
    AUTH_EDIT_PROMOTION_EVENT,
    AUTH_VIEW_PROMOTION_EVENT_DETAIL,
    VIEW_VIOLATE_RENTAL_LIST,
    AUTH_VIEW_MISSION_LIST,
    AUTH_EDIT_MISSION,
    AUTH_VIEW_MISSION_DETAIL,
    AUTH_VIEW_ASSET_LIST,
    AUTH_VIEW_DASHBOARD,
    AUTH_VIEW_SCOOTER_MAINTAIN_LIST,
    AUTH_VIEW_EXPENSE_LIST,
    AUTH_VIEW_EXPENSE_DETAIL,
    AUTH_CREATE_EXPENSE,
    AUTH_VIEW_RETRIEVAL_FINDER,
} from 'constants/permission';
import { RETRIEVAL_FINDER } from "./routes";

export const AUTH_BY_PASS = 'by_pass';
export const GROUP_DASHBOARD = 'dashboard';
export const GROUP_CUSTOMER = 'customer_service';
export const GROUP_MARKETING = 'marketing';
export const GROUP_OPERATION = 'operation';
export const GROUP_AUDIT = 'audit';
export const GROUP_TICKET = 'ticket';
export const GROUP_ADMIN = 'admin';
export const GROUP_PROMOTION = 'promotion';
export const GROUP_TRAFFIC = 'traffic';
export const GROUP_TV = 'tv';

export const sortedGroupList = [
    GROUP_DASHBOARD,
    GROUP_TV,
    GROUP_CUSTOMER,
    GROUP_OPERATION,
    GROUP_TICKET,
    GROUP_MARKETING,
    GROUP_AUDIT,
    GROUP_PROMOTION,
    GROUP_ADMIN,
    GROUP_TRAFFIC,
];

const routesWithAuth = [{
    route: DASHBOARD,
    category: GROUP_DASHBOARD,
    auth: AUTH_VIEW_DASHBOARD,
    isMenuItem: true,
    order: 0,
}, {
    route: TV_MONITOR,
    category: GROUP_TV,
    auth: AUTH_VIEW_DASHBOARD,
    isMenuItem: true,
    order: 0,
}, {
    route: RENTAL_MANAGEMENT,
    category: GROUP_CUSTOMER,
    auth: AUTH_VIEW_RENTAL_LIST,
    isMenuItem: true,
    order: 0,
}, {
    route: RENTAL_DETAIL,
    category: GROUP_CUSTOMER,
    auth: AUTH_VIEW_RENTAL_DETAIL,
    isMenuItem: false,
    order: 0,
}, {
    route: CUSTOMER_FINDER,
    category: GROUP_CUSTOMER,
    auth: AUTH_VIEW_CUSTOMER_FINDER,
    isMenuItem: true,
}, {
    route: RETRIEVAL_FINDER,
    category: GROUP_CUSTOMER,
    auth: AUTH_VIEW_RETRIEVAL_FINDER,
    isMenuItem: true,
}, {
    route: CUSTOMER_DETAIL,
    auth: AUTH_VIEW_CUSTOMER_DETAIL,
    isMenuItem: false,
}, {
    route: SCOOTER_LIST,
    category: GROUP_OPERATION,
    auth: AUTH_VIEW_SCOOTER_LIST,
    isMenuItem: true,
    order: 0,
}, {
    route: SCOOTER_MAP,
    category: GROUP_OPERATION,
    auth: AUTH_VIEW_SCOOTER_LIST,
    isMenuItem: true,
    order: 1,
}, {
    route: SCOOTER_SEARCH,
    category: GROUP_OPERATION,
    auth: AUTH_VIEW_SCOOTER_FINDER,
    isMenuItem: true,
    order: 2,
}, {
    route: ASSET_MANAGEMENT,
    category: GROUP_OPERATION,
    auth: AUTH_VIEW_ASSET_LIST,
    isMenuItem: true,
    order: 3,
}, {
    route: REGULAR_MAINTENANCE,
    category: GROUP_OPERATION,
    auth: AUTH_VIEW_SCOOTER_MAINTAIN_LIST,
    isMenuItem: true,
    order: 4,
}, {
    route: SCOOTER_PROFILE,
    category: GROUP_OPERATION,
    auth: AUTH_VIEW_SCOOTER_DETAIL,
    isMenuItem: false,
}, {
    route: TICKET_LIST,
    auth: AUTH_GET_TICKET_LIST,
    category: GROUP_TICKET,
    isMenuItem: true,
    order: 0,
}, {
    route: TICKET_ASSIGNMENT,
    auth: AUTH_GET_TICKET_LIST,
    category: GROUP_TICKET,
    isMenuItem: true,
    order: 1,
}, {
    route: TICKET_DETAIL,
    auth: AUTH_GET_TICKET,
    isMenuItem: false,
}, {
    route: MY_TICKET,
    auth: AUTH_GET_TICKET_SELF_LIST,
    category: GROUP_TICKET,
    isMenuItem: true,
    order: 2,
}, {
    route: COUPON_HOME,
    category: GROUP_MARKETING,
    auth: AUTH_VIEW_COUPON_LIST,
    isMenuItem: true,
    order: 0,
}, {
    route: NEW_COUPON,
    auth: AUTH_EDIT_COUPON,
    isMenuItem: false,
}, {
    route: NEW_SYSTEM_COUPON,
    auth: AUTH_EDIT_COUPON,
    isMenuItem: false,
}, {
    route: EDIT_COUPON,
    auth: AUTH_VIEW_COUPON_DETAIL,
    isMenuItem: false,
}, {
    route: EDIT_SYSTEM_COUPON,
    auth: AUTH_VIEW_COUPON_DETAIL,
    isMenuItem: false,
}, {
    route: AD_MANAGEMENT,
    category: GROUP_MARKETING,
    auth: AUTH_VIEW_BANNER_LIST,
    isMenuItem: true,
    order: 2,
}, {
    route: SYSTEM_COUPON,
    category: GROUP_PROMOTION,
    auth: AUTH_VIEW_COUPON_LIST,
    isMenuItem: true,
    order: 1,
}, {
    route: PROMOTION_MANAGEMENT,
    category: GROUP_PROMOTION,
    auth: AUTH_VIEW_PROMOTION_EVENT_LIST,
    isMenuItem: true,
    order: 2,
}, {
    route: NEW_PROMOTION,
    category: GROUP_PROMOTION,
    auth: AUTH_EDIT_PROMOTION_EVENT,
    isMenuItem: false,
}, {
    route: PROMOTION_DETAIL,
    category: GROUP_PROMOTION,
    auth: AUTH_VIEW_PROMOTION_EVENT_DETAIL,
    isMenuItem: false,
}, {
    route: MISSION_MANAGEMENT,
    category: GROUP_PROMOTION,
    auth: AUTH_VIEW_MISSION_LIST,
    isMenuItem: true,
    order: 3,
}, {
    route: NEW_MISSION,
    category: GROUP_PROMOTION,
    auth: AUTH_EDIT_MISSION,
    isMenuItem: false,
}, {
    route: MISSION_DETAIL,
    category: GROUP_PROMOTION,
    auth: AUTH_VIEW_MISSION_DETAIL,
    isMenuItem: false,
},{
    route: ORDER_MISSION,
    auth: AUTH_VIEW_MISSION_DETAIL,
    isMenuItem: false,
}, {
    route: NEW_AD,
    auth: AUTH_EDIT_BANNER,
    isMenuItem: false,
}, {
    route: EDIT_AD,
    auth: AUTH_VIEW_BANNER_DETAIL,
    isMenuItem: false,
}, {
    route: ORDER_AD,
    auth: AUTH_VIEW_BANNER_DETAIL,
    isMenuItem: false,
}, {
    route: LOG_RENTAL,
    category: GROUP_AUDIT,
    auth: AUTH_VIEW_AUDIT_LOG_LIST,
    isMenuItem: true,
    order: 0,
}, {
    route: ADMIN,
    auth: AUTH_VIEW_FLEET_USER_LIST,
    category: GROUP_ADMIN,
    isMenuItem: true,
    order: 1,
}, {
    route: VIOLATION_DETAIL,
    category: GROUP_TRAFFIC,
    auth: VIEW_VIOLATE_RENTAL_LIST,
    isMenuItem: false,
    order: 0,
}, {
    route: ADDITIONAL_FEE,
    category: GROUP_TRAFFIC,
    auth: AUTH_VIEW_EXPENSE_LIST,
    isMenuItem: true,
    order: 1,
}, {
    route: ADDITIONAL_FEE_DETAIL,
    category: GROUP_TRAFFIC,
    auth: AUTH_VIEW_EXPENSE_DETAIL,
    isMenuItem: false,
    order: 1,
}, {
    route: NEW_ADDITIONAL_FEE,
    category: GROUP_TRAFFIC,
    auth: AUTH_CREATE_EXPENSE,
    isMenuItem: false,
    order: 1,
}];

export default routesWithAuth;
