import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import Datetime from 'components/Datetime';
import Button from 'components/Form/Button';
import Cell from 'components/ListView/Cell';
import { toggleGSHPassRefundModal } from 'actions';
import { PAYMENT_CHANNEL_GOPOCKET, PAYMENT_CHANNEL_TAPPAY } from 'constants/rental';
import AuthFeature from 'components/AuthFeature';
import { AUTH_REFUND_CUSTOMER_TRAVEL_PASS, AUTH_VIEW_USER_TRAVEL_PASS_DETAIL } from 'constants/permission';
import ReissueInvoice from 'components/ReissueInvoice';


class ListRow extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape({}).isRequired,
    }

    handleKeyDown = e => {
        e.key === 'Enter' && e.preventDefault();
    };

    handleRefund = (data) => e => {
        const { user_pass_id, subtotal, payment_channel } = data;
        const { dispatch } = this.props;
        if (!data.is_refunded) {
            return dispatch(toggleGSHPassRefundModal({
                payment_channel,
                user_pass_id,
                price: subtotal
            }));
        }
        else {
            return;
        }
    }

    renderField({ data, name, width }) {

        let el;
        switch (name) {
        case 'valid_from':
        case 'valid_to':
            el = <Datetime time={ data[name] } />;
            break;
        case 'payment_channel':
            el = <Translate value={ `rental.daypass_payment_channel.${ data.payment_channel }` } />;
            break;
        case 'state':
            el = <Translate value={ `customer.pass_state_${ data.state }` } />;
            break;
        case 'refund_amount':
            if (data.is_refunded && data.payment_channel === PAYMENT_CHANNEL_GOPOCKET) {
                el = `${ data.refund_subtotal } (${ data.refund_price }; ${ I18n.t('rental.gogoro_rewards') }: ${ data.refund_point })`;
            }
            else if (data.is_refunded && data.payment_channel === PAYMENT_CHANNEL_TAPPAY) {
                el = data.refund_price;
            }
            else {
                el = '--';
            }
            break;
        case 'refund_btn':
            el =(
                <>
                    {
                        (!data.is_refunded) && (
                            <AuthFeature requiredList={ [AUTH_REFUND_CUSTOMER_TRAVEL_PASS] }>
                                <div onKeyDown={ this.handleKeyDown }>
                                    <Button
                                        outline
                                        type="button"
                                        onClick={ this.handleRefund(data) }
                                        stopTriggerByTab
                                        disabled={ data.is_refunded }
                                    >
                                        <Translate value="rental.refund" />
                                    </Button>
                                </div>
                            </AuthFeature>
                        )
                    }
                    <ReissueInvoice
                        id={ data.user_pass_id }
                        entryType={ AUTH_VIEW_USER_TRAVEL_PASS_DETAIL }
                        isValid
                        price={ data.charge_price }
                        btnText="rental.reissue_invoice"
                    />
                </>
            );
            break;
        default:
            el = data[name] || '--';
            break;
        }

        return (
            <Cell
                key={ `${ data.id }-${ name }` }
                name={ name }
                style={ {
                    width,
                } }
            >
                { el }
            </Cell>
        );
    }

    render() {
        const { columns, data } = this.props;

        return columns.map(({ key, width }, i) => {
            return this.renderField({ data, width, name: key, });
        });
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(ListRow);
