import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import ModalWrapper from 'components/ModalWrapper';
import { Translate, I18n } from 'react-redux-i18n';
import Form from 'components/Form';
import { CardBody, Card } from 'reactstrap';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Form/Button';
import Input, {
    TYPE_NUMBER,
} from 'components/Form/Input';


const RefundFee = ({
    show,
    charged,
    onSubmit,
    onClose,
}) => {
    const [inProgress, setInProgress] = useState(false);
    const handleSubmit = e => {
        setInProgress(true);
        onSubmit().then(() => {
            setInProgress(false);
        });
    };
    const handleClose = e => {
        onClose();
    };
    return (
        <>
            {
                show ? (
                    <ModalWrapper>
                        <Modal
                            className="refund-modal"
                            title={ I18n.t('rental.refund') }
                            disabledCloseOnBg
                            onClose={ handleClose }
                        >
                            <Form
                                inline
                                onSubmit={ handleSubmit }
                                inProgress={ inProgress }
                                stopSubmitByEnter
                            >
                                <Card>
                                    <CardBody>
                                        <Input
                                            type={ TYPE_NUMBER }
                                            caption="rental.total_charged"
                                            value={ charged }
                                            viewMode
                                        />
                                    </CardBody>
                                </Card>
                                <ButtonGroup>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        stopTriggerByTab
                                    >
                                        <Translate value="apply" />
                                    </Button>
                                </ButtonGroup>
                            </Form>
                        </Modal>
                    </ModalWrapper>
                ) : null
            }
        </>
    );
};

RefundFee.propTypes = {
    show: PropTypes.bool,
    charged: PropTypes.number,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
};
RefundFee.defaultProps = {
    show: false,
    charged: 0,
    onSubmit: () => {},
    onClose: () => {},
};

export default RefundFee;
