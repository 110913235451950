import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'components/Datetime';
import Cell from 'components/ListView/Cell';
import { NavLink } from 'react-router-dom';
import Plate from 'components/Scooter/Plate';
import RentalState from 'components/Scooter/RentalState';
import CorporateType from 'components/Customer/CorporateType';
import PaymentStatus from 'components/Customer/PaymentStatus';
import PaymentMethod from 'components/Customer/PaymentMethod';
import { thousandsSeparator, convertDuration, convertDistance } from 'helpers/util';
import CopyToClipBoard from 'components/CopyToClipBoard';
import AuthFeature from 'components/AuthFeature';
import {
    AUTH_VIEW_RENTAL_DETAIL,
} from 'constants/permission';
import {
    RENTAL_DETAIL,
} from 'constants/routes';
import buildActualPath from 'helpers/build-actual-path';


class ListRow extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape({
            rental_id: PropTypes.string.isRequired,
            customer_id: PropTypes.string.isRequired,
            scooter_id: PropTypes.string,
            plate_no: PropTypes.string,
            reserve_time: PropTypes.string,
            start_time: PropTypes.string,
            end_time: PropTypes.string,
            duration: PropTypes.number,
            rental_status: PropTypes.number,
            price: PropTypes.number,
            payment_status: PropTypes.number,
            payment_method: PropTypes.number,
            corporate_type: PropTypes.number,
            distance: PropTypes.number,
        }).isRequired,
    };

    renderField({ data, name, width }) {
        let el = null;

        switch (name) {
        case 'rental_id':
            el = (
                <CopyToClipBoard>
                    <AuthFeature
                        requiredList={ [AUTH_VIEW_RENTAL_DETAIL] }
                        fallback={ () => data.rental_id }
                    >
                        <NavLink to={ buildActualPath(RENTAL_DETAIL, { rentalId: data.rental_id }) }>
                            { data.rental_id }
                        </NavLink>
                    </AuthFeature>
                </CopyToClipBoard>
            );
            break;
        case 'customer_id':
            el = (
                <CopyToClipBoard>
                    { data.customer_id }
                </CopyToClipBoard>
            );
            break;
        case 'plate_no':
            el = (<Plate id={ data.scooter_id } plateNo={ data.plate_no } modal={ false } />);
            break;
        case 'reserve_time':
        case 'start_time':
        case 'end_time':
        case 'user_travel_pass_end_time':
            el = (<Datetime time={ data[name] } />);
            break;
        case 'duration':
            el = convertDuration(data.duration);
            break;
        case 'rental_status':
            el = (<RentalState className="rental-status" status={ data.rental_status } showSpecific />);
            break;
        case 'price':
            el = thousandsSeparator(data.price);
            break;
        case 'payment_status':
            el = (<PaymentStatus status={ data.payment_status } />);
            break;
        case 'payment_method':
            el = (<td><PaymentMethod status={ data.payment_method } /></td>);
            break;
        case 'corporate_type':
            el = (<CorporateType type={ data.corporate_type } />);
            break;
        case 'distance':
            el = convertDistance(data.distance);
            break;
        default:
            el = data[name];
            break;
        }

        return (
            <Cell
                key={ `${ data.id }-${ name }` }
                name={ name }
                style={ {
                    width,
                } }
            >
                { el }
            </Cell>
        );
    }

    render() {
        const { columns, data } = this.props;

        return columns.map(({ key, width }, i) => {
            return this.renderField({ data, width, name: key, });
        });
    }
}

export default ListRow;
