import {
    TRANSACTION_RENTAL,
    TRANSACTION_ADDITIONAL_FEE,
    TRANSACTION_TRAVEL_PASS,
    CARD_TYPE_VISA,
    CARD_TYPE_MASTERCARD,
    CARD_TYPE_JCB,
    CARD_TYPE_UNION_PAY,
    CARD_TYPE_AMEX,
    CARD_TYPE_DISCOVERY_CARD,
} from 'constants/retrieval';

const retrieval = {
    'retrieval_finder': '調單查詢',
    [`transaction_${TRANSACTION_RENTAL}`]: '租賃',
    [`transaction_${TRANSACTION_ADDITIONAL_FEE}`]: '衍生費用',
    [`transaction_${TRANSACTION_TRAVEL_PASS}`]: '日租方案',
    [`card_type_${CARD_TYPE_VISA}`]: 'VISA',
    [`card_type_${CARD_TYPE_MASTERCARD}`]: 'MasterCard',
    [`card_type_${CARD_TYPE_JCB}`]: 'JCB',
    [`card_type_${CARD_TYPE_UNION_PAY}`]: '銀聯',
    [`card_type_${CARD_TYPE_AMEX}`]: '美國運通卡',
    [`card_type_${CARD_TYPE_DISCOVERY_CARD}`]: '發現卡',
    'rental_id': '租賃紀錄編號',
    'order_id': 'EC訂單編號',
    'rental_amount': '金額',
    'card_first': '信用卡號前六碼',
    'card_last': '信用卡號後四碼',
    'type': '類別',
    'issuer': '發卡行',
    'card_type': '卡別'
};

export default retrieval;
