import React, { useState } from 'react';
import Modal from 'components/Modal';
import Form from 'components/Form';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Form/Button';
import Input, {
    TYPE_NUMBER,
} from 'components/Form/Input';
import { Translate } from 'react-redux-i18n';
import './confirm-modal.scss';

const ConfirmModal = ({ title, caption, show, onClose, onSubmit, value }) => {
    const [inProgress, setInProgress] = useState(false);

    const handleSubmit = async () => {
        setInProgress(true);
        await onSubmit(value);
        setInProgress(false);
    }

    return show ? (
        <Modal
            title={ title }
            disableCloseBtn
            disabledCloseOnBg
        >
            <Form inline onSubmit={ handleSubmit } inProgress={ inProgress } stopSubmitByEnter>
                <Input
                    assignedstyle="confirm-value"
                    type={ TYPE_NUMBER }
                    caption={ caption}
                    value={ value }
                    viewMode
                />
                <ButtonGroup>
                    <Button
                        type="button"
                        color="primary"
                        onClick={ onClose }
                        outline
                    >
                        <Translate value="cancel" />
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                    >
                        <Translate value="ok" />
                    </Button>
                </ButtonGroup>
            </Form>
        </Modal>
    ) : null
}

export default ConfirmModal;
