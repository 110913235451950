/* Define the routes */
const { PUBLIC_URL } = process.env;

export const ROOT = PUBLIC_URL + '/' || '/';
export const MY_TASK = `${ ROOT }task/`;
export const SCOOTER_LIST = `${ ROOT }scooter/`;
export const SCOOTER_MAP = `${ ROOT }scooter-map/`;
export const SCOOTER_PROFILE = `${ ROOT }scooter/:scooterId`;
export const SCOOTER_HISTORY = `${ ROOT }scooter/:scooterId/history`;
export const SCOOTER_SEARCH = `${ ROOT }scooter/search`;
export const RENTAL_MANAGEMENT = `${ ROOT }rental/`;
export const RENTAL_DETAIL = `${ ROOT }rental/:rentalId`;
export const AD_MANAGEMENT = `${ ROOT }ad/`;
export const NEW_AD = `${ AD_MANAGEMENT }new`;
export const EDIT_AD = `${ AD_MANAGEMENT }edit/:adId`;
export const ORDER_AD = `${ AD_MANAGEMENT }order`;
export const LOGIN = `${ ROOT }login`;
export const COUPON_HOME = `${ ROOT }coupon/`;
export const NEW_COUPON = `${ COUPON_HOME }new`;
export const EDIT_COUPON = `${ COUPON_HOME }edit/:couponId`;
export const SYSTEM_COUPON = `${ ROOT }coupon/system/`;
export const NEW_SYSTEM_COUPON = `${ SYSTEM_COUPON }new`;
export const EDIT_SYSTEM_COUPON = `${ SYSTEM_COUPON }edit/:couponId`;
export const PROMOTION_MANAGEMENT = `${ ROOT }promotion/`;
export const NEW_PROMOTION = `${ PROMOTION_MANAGEMENT }new`;
export const PROMOTION_DETAIL = `${ PROMOTION_MANAGEMENT }:promotionId`;
export const CUSTOMER_FINDER = `${ ROOT }customer/search`;
export const RETRIEVAL_FINDER = `${ ROOT }customer/retrieval`;
export const CUSTOMER_DETAIL = `${ ROOT }customer/:customerId`;
export const TICKET_LIST = `${ ROOT }ticket/`;
export const TICKET_DETAIL = `${ ROOT }ticket/:ticketId`;
export const TICKET_ASSIGNMENT = `${ ROOT }ticket-assignment`;
export const MY_TICKET = `${ ROOT }ticket/me`;
export const LOG_RENTAL = `${ ROOT }log/rental`;
export const ADMIN = `${ ROOT }admin/`;
export const VIOLATION_DETAIL = `${ ROOT }violation/:rentalId`;
export const MISSION_MANAGEMENT = `${ ROOT }mission/`;
export const NEW_MISSION = `${ MISSION_MANAGEMENT }new`;
export const MISSION_DETAIL = `${ MISSION_MANAGEMENT }:missionId`;
export const ORDER_MISSION = `${ MISSION_MANAGEMENT }order`;
export const ASSET_MANAGEMENT = `${ ROOT }asset/`;
export const DASHBOARD = `${ ROOT }dashboard/`;
export const TV_MONITOR = `${ ROOT }tv-monitor/`;
export const REGULAR_MAINTENANCE = `${ ROOT }regular-maintenance/`;
export const ADDITIONAL_FEE = `${ ROOT }additional-fee/`;
export const NEW_ADDITIONAL_FEE = `${ ADDITIONAL_FEE }new`;
export const ADDITIONAL_FEE_DETAIL = `${ ADDITIONAL_FEE }:expenseId`;

