import {
    RENTAL_HISTORY_FULL,
    RENTAL_HISTORY_SCOOTER,
    RENTAL_HISTORY_CUSTOMER,
} from './index';
import allHeader from './column/all';
import customerHeader from './column/customer';
import scooterHeader from './column/scooter';

const ListHeader = (type) => {
    let columns = [];

    switch (type) {
    case RENTAL_HISTORY_SCOOTER:
        columns = scooterHeader;
        break;
    case RENTAL_HISTORY_CUSTOMER:
        columns = customerHeader;
        break;
    case RENTAL_HISTORY_FULL:
        columns = allHeader;
        break;
    default:
        // do nothing
        break;
    }

    return columns;
};

export default ListHeader;

