import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import { Input, Button, Form } from 'reactstrap';
import AuthView from "components/AuthView";
import PageHeader from 'components/PageHeader';
import { updateDocumentTitle } from 'actions';
import SearchResultWrap from 'components/SearchResultWrap';
import headerConfig from './ListHeader';
import ListRow from './ListRow';
import ListView from 'components/ListView';
import { useLazyGetRetrivalQuery } from 'redux/hook/useCustomer';

import './retrieval-finder.scss';

const RetrievalFinder = () => {
    const inputEl = useRef();
    const [searchResultMessage, setSearchResultMessage] = useState('');
    const [retrievalData, setRetrievalData] = useState([]);
    const [getRetrival] = useLazyGetRetrivalQuery();
    const location = useLocation();
    const dispatch = useDispatch();
    const i18n = useSelector(state => state.i18n);

    useEffect(() => {
        const { pathname } = location;
        dispatch(updateDocumentTitle(`routes.${ pathname }`));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

    }, [i18n]);

    const ListItem = ({ columns, rowData }) => {
        return (
            <ListRow
                key={ `retrival-${ rowData.order_id }` }
                columns={ columns }
                data={ rowData }
            />
        )
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { data, error } = await getRetrival({ order_id: inputEl.current.value });
        if (error) {
            setSearchResultMessage(I18n.t('general_error_message'));
            return;
        }
        const { data_list } = data;
        setRetrievalData(data_list);
    }

    return (
        <AuthView className="retrieval-finder">
            <PageHeader>
                <Translate value="retrieval.retrieval_finder" />
            </PageHeader>
            <Form className="filter" onSubmit={ handleSubmit }>
                <Input className="search-text" innerRef={ inputEl } placeholder={ I18n.t('retrieval.order_id') } type="text" />
                <Button type="submit">
                    <Translate value="search" />
                </Button>
            </Form>
            <SearchResultWrap
                show={ retrievalData?.length !== 0 }
                message={ searchResultMessage }
            >
                <ListView
                    header={ headerConfig }
                    list={ retrievalData }
                    renderListRow={ ListItem }
                    dataKey="order_id"
                />
            </SearchResultWrap>
        </AuthView>
    )
}

export default RetrievalFinder;
