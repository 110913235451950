const ListHeader = [{
    name: 'valid_from',
    value: 'rental.daypass_valid_from',
    width: 120,
}, {
    name: 'valid_to',
    value: 'rental.daypass_valid_to',
    width: 120,
}, {
    name: 'payment_channel',
    value: 'payment_method',
    width: 120,
}, {
    name: 'charge_price',
    value: 'price',
    width: 80,
}, {
    name: 'charge_point',
    value: 'rental.gogoro_rewards',
    width: 100,
}, {
    name: 'state',
    value: 'customer.pass_state',
    width: 80,
}, {
    name: 'refund_amount',
    value: 'customer.refund_amount',
    width: 180,
}, {
    name: 'refund_btn',
    value: 'action_field',
    width: 220,
}];

export default ListHeader;
